import { useMyBookings } from '@/api/hooks/use-my-bookings'
import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer'
import { useLayerInfo } from '@/api/hooks/useLayerInfo'
import { useLayerView } from '@/api/hooks/useLayerView'
import { useMetadata } from '@/api/hooks/useMetadata'
import { linkLayerInfoProperties } from '@/components/layout/Sidebar/Layers/LayerInfoModal'
import MapStage from '@/components/shared/map/stage/MapStage'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useProjectStore } from '@/stores/projectStore'
import { formatLocalDateToAPI } from '@/utils/helpers/dates.helpers'
import { addMinutes } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import PointsLayer from '../point/PointsLayer'
import PolygonsLayer from '../polygon/PolygonsLayer'
import Tooltip from '../tooltip/Tooltip'
import MapCoverLayer from './MapCoverLayer'

const MapContainer = () => {
  const activeLayer = useGlobalStore((state) => state.activeLayer)
  const selection = useGlobalStore((state) => state.selection)
  const nodes = useProjectStore((state) => state.nodes)
  const setMapLayer = useMapStore((state) => state.setMapLayer)
  const setPopupLayer = useGlobalStore((state) => state.setPopupLayer)
  const { hasActivePermanent } = useMyBookings()

  const node = nodes.find((n) => n.id == activeLayer)
  const hasOwnView = node?.ownView
  const layer = hasOwnView ? node.id : node?.parent || activeLayer

  const [viewId, setViewId] = useState(layer)

  const { layerView } = useLayerView(Number(viewId))
  const { metadata } = useMetadata()
  const { data } = useBookingsForLayer(
    Number(viewId),
    formatLocalDateToAPI(selection.startDate),
    formatLocalDateToAPI(addMinutes(selection.endDate, -30))
  )

  const { data: layerInfo } = useLayerInfo(Number(layer))

  const currentNode = useMemo(() => {
    if (layerInfo && metadata && metadata.layers) {
      return metadata.layers[layerInfo?.info?.type_uid]
    }
    return null
  }, [layerInfo, metadata])
  const props = linkLayerInfoProperties(
    currentNode?.plugin_data,
    layerInfo?.info?.plugin_data
  )

  const isPopup = useMemo(
    () =>
      !!props.find((prop) => prop.name === 'popup' && prop.value === 'true'),
    [props]
  )

  useEffect(() => {
    if (node && isPopup) {
      setViewId(Number(node?.parent))
      // setPopupLayer(Number(layer))
    } else if (node && !isPopup) {
      setViewId(layer)
    }
  }, [layer, isPopup, node])

  useEffect(() => {
    setMapLayer(Number(layer))
  }, [viewId, activeLayer])

  const nodeFields = useMemo(
    () => findFieldsByTypes(metadata?.nodes),
    [metadata?.nodes]
  )
  const points = useMemo(() => {
    return layerView?.points?.map((point) => {
      const fieldEntries: Record<string, any> = extractFieldEntries(point)

      const fieldsMeta = nodeFields[point.type_uid] || []

      const fields = fieldsMeta?.map((field) => {
        const fieldValue = fieldEntries[`field_${field.id}`]
        return { ...field, value: fieldValue }
      })

      return { ...point, fields }
    })
  }, [nodeFields, layerView?.points])

  return (
    <MapStage>
      <MapCoverLayer view={layerView?.view} />
      <PolygonsLayer polygons={layerView?.polygons} />
      <PointsLayer
        nodes={metadata?.rawNodes}
        points={points}
        options={layerView?.options}
        bookings={data?.bookings}
        hasActivePermanent={hasActivePermanent}
      />
      <Tooltip />
    </MapStage>
  )
}

MapContainer.whyDidYouRender = true

export default MapContainer

export const findFieldsByTypes = (obj: any) => {
  if (!obj || !obj.length) return {}
  if (typeof obj !== 'object' || !Array.isArray(obj)) return {}

  let types = {}

  obj.forEach((n) => {
    const pluginData = n['plugin_data']
    if (!n || typeof n !== 'object') return
    const key = n['uid'] || n['name']
    if (!key) {
      console.error('no key found for fields obj')
      return
    }

    const fields = findFields(pluginData)
    types[key] = fields
  })

  return types
}

export const findFields = (pluginData: any) => {
  if (!pluginData) return []

  const fields = Object.values(pluginData)
    .map((d) => d.fields)
    .find((fields) => Array.isArray(fields))

  return fields || []
}

interface PluginData {
  [key: string]: any
}

interface Data {
  plugin_data?: Record<string, PluginData>
}

export const extractFieldEntries = (data: Data): Record<string, any> => {
  if (!data || !data.plugin_data) return {}

  const fieldEntries = Object.values(data.plugin_data).reduce((acc, plugin) => {
    Object.entries(plugin).forEach(([key, value]) => {
      if (key.startsWith('field_')) {
        acc[key] = value
      }
    })
    return acc
  }, {} as Record<string, any>)

  return fieldEntries
}
