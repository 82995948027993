import { DefaultButton } from '@/components/ui/button/DefaultButton';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import { Field, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import Grid from '@/ui/components/Grid';
import { FormLabel } from '@/ui/components/Form/FormLabel';
import { SelectField } from '@/ui/components/Field/Select';
import { FormControls } from '@/components/ui/form/FormControls';
import { useMaintenanceDialogStore } from './MaintenanceModal';
import { InputField } from '@/ui/components/Field/Input';
import { useIntl } from 'react-intl';
import { translate } from '@/i18n';

const d = new Date();
const offset = d.getTimezoneOffset();
export const maintenanceTypes = [
  { value: '1', label: 'Уборка', alias: 'cleaning' },
  { value: '2', label: 'Техническое облуживание', alias: 'maintenance' }
];

const MaintenanceForm: React.FC = () => {
  // data selectors
  const onClose = useMaintenanceDialogStore(state => state.onClose);
  const seat = useMaintenanceDialogStore(state => state.seat);
  const employee = useMaintenanceDialogStore(state => state.employee);
  const intl = useIntl();

  const handleFormSubmit = async (values, { setSubmitting }) => {
    onClose();
    setTimeout(() => {
      alert(intl.formatMessage({ id: 'service-submitted' }));
    }, 100);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      enableReinitialize
      initialValues={{
        seat,
        employee
      }}
    >
      {({ handleSubmit, values, isSubmitting, errors, isValid, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormLabel>{translate('place')}</FormLabel>
                <Field
                  name='seat'
                  $fullWidth
                  required={true}
                  placeholder={intl.formatMessage({ id: 'place' })}
                  component={InputField}
                  disabled={true}
                  style={{ opacity: 0.5 }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormLabel>{translate('employee')}</FormLabel>
                <Field
                  name='employee'
                  $fullWidth
                  required={true}
                  placeholder={intl.formatMessage({ id: 'employee' })}
                  component={InputField}
                  disabled={true}
                  style={{ opacity: 0.5 }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormLabel>{translate('service-type')}</FormLabel>
                <Field
                  name='type'
                  $fullWidth
                  required={true}
                  component={SelectField}
                  options={maintenanceTypes.map(item => ({ ...item, label: intl.formatMessage({ id: item.alias }) }))}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormLabel>{translate('more-info')}</FormLabel>
                <Field
                  name='more'
                  $fullWidth
                  placeholder={intl.formatMessage({ id: 'describe' })}
                  component={InputField}
                  textarea
                  rows={10}
                />
              </Grid>
            </Grid>

          </FormContainer>

          <ControlsWrapper>
            <FormControls>
              <PrimaryButton $fullWidth type='submit' disabled={isSubmitting}>
                {translate('send')}
              </PrimaryButton>
              <DefaultButton $fullWidth type='button' onClick={onClose}>{translate('cancel')}</DefaultButton>
            </FormControls>
          </ControlsWrapper>

        </form>
      )}
    </Formik>
  );
};

const FormContainer = styled.div`
  padding: 1rem 0;
  max-height: 100%;
`;

export default MaintenanceForm;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;