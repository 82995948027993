import { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import bigChair from '@/images/big-chair.png'
import icBooking from '@/images/ic-booking.png'
import icChair from '@/images/ic-chair.png'
import icEmp from '@/images/ic-emp.png'

import { API } from '@/api'
import { useMyBookings } from '@/api/hooks/use-my-bookings'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useUser } from '@/api/hooks/useUser'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import { useToast } from '@/components/shared/toast/useToast'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import EmailIcon from '@/components/ui/icon/EmailIcon'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { useProject } from '@/hooks/useProject'
import { translate } from '@/i18n'
import { useGlobalStore } from '@/stores/globalStore'
import { useProjectStore } from '@/stores/projectStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import Avatar from '@/ui/components/Avatar'
import { formatPhoneNumber } from '@/utils/helpers/phone.helpers'
import { easePoly } from 'd3-ease'
import Draggable from 'react-draggable'
import { ErrorBoundary } from 'react-error-boundary'
import { useQuery } from 'react-query'
import { animated, useTransition } from 'react-spring'
import DraggableControl from '../../app/components/ui/draggable/draggable-control/DraggableControl'
import { makeUserCardData } from './EmployeeBar'
import Grid from './Grid'
import CloseIcon from './icons/CloseIcon'
import { bookingDialog } from './shared/booking/form/BookingModal'
import {
  defaultMapPointImage,
  getImageLink,
  isBookableForMe
} from './shared/map/point/Point'
import { findFieldsByTypes } from './shared/map/stage/MapContainer'

const linkProperties = (node, meta) => {
  if (!node || !meta) return []
  const keys = Object.keys(node)

  const data = keys
    .map((key) => meta[key])
    .filter((v) => v)
    .find((item) => item.fields)?.fields
  const nodeData = keys
    .map((key) => node[key])
    .filter((v) => v)
    .find((item) => item.fields)?.fields

  if (!data || !nodeData) return []
  const props = data
    .map((item) => {
      const info = nodeData.find((n) => n.field_id === item.id)

      if (!info || typeof info.value === 'object') return null

      return {
        name: String(item.name),
        value: String(info.value)
      }
    })
    .filter((v) => v)

  return props.filter((prop) => !prop.name.startsWith('#'))
}

export const formatBooleanValue = (value: string) => {
  if (value == 'false') return 'Нет'
  if (value == 'true') return 'Да'
  return value
}

interface PluginData {
  [key: string]: any
}

interface Data {
  plugin_data?: Record<string, PluginData>
}

export const extractFieldEntries = (data: Data): Record<string, any> => {
  if (!data || !data.plugin_data) return {}

  const fieldEntries = Object.values(data.plugin_data).find(
    (v) => v['fields']
  )?.['fields']

  return fieldEntries
}

const combineFields = (fields, metaFields) => {
  if (!fields || !metaFields) return []

  const fieldsMapper = fields.reduce((acc, val) => {
    return {
      ...acc,
      [val['field_id']]: val['value']
    }
  }, {} as Record<string, any>)

  return metaFields.map((field) => ({
    ...field,
    value: fieldsMapper[field.id]
  }))
}

const SeatBar = () => {
  const [open, setOpen] = useState(false)
  const [section, setSection] = useState<'user' | 'seat' | null>('seat')
  const { enqueueToast } = useToast()
  // const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
  const { workspaceId, projectId } = useProject()
  const { hasActivePermanent } = useMyBookings()

  const categories = useUserStore((state) => state.categories)
  const seat = useGlobalStore((state) => state.seat)
  const globalSection = useGlobalStore((state) => state.section)
  const setGlobalSection = useGlobalStore((state) => state.setSection)
  const setSeat = useGlobalStore((state) => state.setSeat)
  const seatEmployee = useGlobalStore((state) => state.seatEmployee)
  const role = useUserStore((state) => state.role)
  const me = useUserStore((state) => state.user)
  const fullMode = useProjectStore((state) => state.fullMode)

  const { metadata } = useMetadata()

  const { data, refetch } = useQuery(
    'seat_info',
    () =>
      API.projects.getNodeInfo({
        workspaceId: Number(workspaceId),
        projectId: Number(projectId),
        nodeId: Number(seat)
      }),
    { enabled: !!(workspaceId && projectId && seat), refetchOnMount: false }
  )

  const { data: userData } = useUser(seatEmployee)
  const { data: extra } = useUserCardFields()

  const user = makeUserCardData(userData?.user, extra?.fields)

  useEffect(() => {
    if (!seat) {
      // setTimeout(() => {
      //   setSection(null)
      // }, 300)
    } else {
      refetch()
    }

    if (seat) {
      // setSection(null)
      setOpen(true)
    }
  }, [seat])

  useEffect(() => {
    if (globalSection) {
      setSection(globalSection)
      setGlobalSection(null)
    }
  }, [globalSection])

  useEffect(() => {
    if (seatEmployee && userData) {
      setSection('user')
    }
  }, [seatEmployee, userData])

  const nodes = metadata?.rawNodes || {}
  const seatInfo =
    Object.keys(nodes).find((key) => key === data?.node_info.type_uid) || ''
  const meta = metadata?.rawNodes[seatInfo]
  const nodeFields = useMemo(
    () => findFieldsByTypes(metadata?.nodes),
    [metadata?.nodes]
  )
  const fields = combineFields(
    extractFieldEntries(data?.node_info as any),
    nodeFields[data?.node_info.type_uid || '']
  )

  const isBookingFree = fields.some(
    (field) => field.name == '#bookingfree' && field.value == true
  )

  // @ts-ignore
  const props = linkProperties(data?.node_info.plugin_data, meta?.plugin_data)
  const imgSrc = getImageLink(meta?.icon) || getImageLink(defaultMapPointImage)
  const avaliable = isBookableForMe(data?.node_info.plugin_data, categories)

  const transitions = useTransition(seat, {
    from: { opacity: 0, translateY: 100, translateX: '-50%' },
    enter: { opacity: 1, translateY: 0, translateX: '-50%' },
    leave: { opacity: 0, translateY: 100, translateX: '-50%' },
    config: {
      duration: 300,
      easing: easePoly.exponent(2)
    }
  })

  const toggleSection = (name) => {
    if (section && section !== name) {
      setSection(name)
    } else if (section === null) {
      setSection(name)
    } else {
      setSection(null)
    }
  }

  const availableForBooking = avaliable && fullMode
  const refWrapper = useRef<HTMLDivElement>(null)

  const onClose = () => {
    if (open) {
      setSeat(null)
      setOpen(false)
    }
  }

  const isBookable = useMemo(
    () =>
      (availableForBooking && role === RolesEnum.Admin) ||
      (isBookingFree && !hasActivePermanent),
    [role, availableForBooking, isBookingFree, seat]
  )
  const copyToClipboard = () => {
    const textField = document.createElement('textarea')
    textField.innerText = String(window.location)
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    enqueueToast(
      {
        title: 'Успешно',
        message: 'URL-адрес объекта скопирован в буфер обмена'
      },
      { variant: 'success' }
    )
  }

  useOnClickOutside(refWrapper, onClose)

  return transitions((style, item) =>
    item ? (
      <Draggable handle=".handle">
        <Wrapper ref={refWrapper} style={style}>
          {data && data.node_info && (
            <>
              <DraggableControl className="handle" />
              <Title>{data?.node_info.name}</Title>
              {isBookable ? (
                <SectionsWrapper>
                  {isBookable && (
                    <Section
                      onClick={() =>
                        bookingDialog({
                          nodeId: data.node_info.id,
                          isBookingFree: isBookingFree
                        })
                      }
                    >
                      <img src={icBooking} />
                      <SectionTitle $expanded={section === null}>
                        {translate('book')}
                      </SectionTitle>
                    </Section>
                  )}
                  {!seatEmployee && (
                    <Section
                      $active={section === 'seat'}
                      onClick={toggleSection.bind(null, 'seat')}
                    >
                      <img src={icChair} />
                      <SectionTitle $expanded={section === null}>
                        {translate('about-object')}
                      </SectionTitle>
                    </Section>
                  )}
                  {seatEmployee && (
                    <Section
                      $active={section === 'user'}
                      onClick={toggleSection.bind(null, 'user')}
                    >
                      <img src={icEmp} />
                      <SectionTitle $expanded={section === null}>
                        {translate('about-employee')}
                      </SectionTitle>
                    </Section>
                  )}
                  <Section onClick={() => setSeat(null)}>
                    <CloseIcon color="#000" />
                    <SectionTitle $expanded={section === null}>
                      {translate('close')}
                    </SectionTitle>
                  </Section>
                </SectionsWrapper>
              ) : (
                <Close onClick={onClose}>
                  <CloseIcon color="#000" />
                </Close>
              )}
              <Container $expanded={section !== null}>
                {section === 'seat' && data && data.node_info && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <img src={imgSrc || bigChair} />
                    </div>

                    <Table>
                      <tr>
                        <td>{translate('type')}</td>
                        <td>{meta?.name}</td>
                      </tr>

                      <tr>
                        <td>{translate('location')}</td>
                        <td>
                          {[...data?.node_info?.parent_name]
                            .filter((v) => v)
                            .join('. ')}
                        </td>
                      </tr>

                      <ErrorBoundary fallback={<></>}>
                        {props
                          .filter((v) => v.value)
                          .map((property) => (
                            <tr key={property.name}>
                              <td>{property.name}</td>
                              <td>{formatBooleanValue(property.value)}</td>
                            </tr>
                          ))}
                      </ErrorBoundary>

                      {/* <Grid.Row $cols="2fr">
                                            <PrimaryButton $fullWidth type="button" onClick={() => {
                                                maintenanceDialog({
                                                    seat: data.node_info.name,
                                                    employee: me?.display || ''
                                                })
                                            }}>
                                                {translate('service')}
                                            </PrimaryButton>
                                        </Grid.Row> */}
                    </Table>
                  </>
                )}
                {section === 'user' && userData && userData.user && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <Avatar
                        src={API.user.getAvatar({ userId: userData.user.id })}
                        size="large"
                      />
                      <Name>{userData.user.display}</Name>
                    </div>

                    <Table>
                      {role === RolesEnum.Admin &&
                        user
                          .filter((v) => v.uid.includes('booking_category'))
                          .map((property) => (
                            <tr key={property.uid}>
                              <td>{translate(property.alias)}</td>
                              <td>{property.value}</td>
                            </tr>
                          ))}
                      {user
                        .filter(
                          (v) => v.value && !v.uid.includes('booking_category')
                        )
                        .map((property) => (
                          <tr key={property.uid}>
                            <td>{property.name}</td>
                            <td>
                              {isEmail(property.value) ? (
                                <a href={`mailto:${property.value}`}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: 4,
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexShrink: 0
                                      }}
                                    >
                                      <EmailIcon />
                                    </div>
                                    <div> {property.value}</div>
                                  </div>
                                </a>
                              ) : property.type === 'phone' ? (
                                formatPhoneNumber(property.value)
                              ) : (
                                property.value
                              )}
                            </td>
                          </tr>
                        ))}
                    </Table>
                  </>
                )}
                <div style={{ marginTop: 16 }}>
                  <PrimaryButton
                    $fullWidth
                    type="button"
                    onClick={copyToClipboard}
                  >
                    {translate('copy-url')}
                  </PrimaryButton>
                </div>
              </Container>
            </>
          )}
        </Wrapper>
      </Draggable>
    ) : (
      ''
    )
  )
}

export default SeatBar

const Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  &::before {
    content: ' ';
    position: absolute;
    border-radius: 50%;
    top: -5px;
    left: -5px;
    width: 40px;
    height: 40px;
    background: #fff975;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`

function isEmail(email: string) {
  var re = /\S+@\S+\.\S+/

  return re.test(email)
}

const Table = styled.table`
  margin-top: 12px;

  //tr td:last-child {
  //  width: 1%;
  //  white-space: nowrap;
  //}

  td,
  th {
    text-align: left;
  }

  td {
    font-size: 12px;
    //width: 50%;
    vertical-align: middle;
    white-space: nowrap;
  }

  tr {
    padding: 1rem 0px;
  }

  tr td:first-child {
    padding: 6px 12px 6px 0;
    width: 30%;
    white-space: break-spaces;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  tr td:last-child {
    font-weight: 700;
    word-break: break-word;
    white-space: break-spaces;
    padding: 6px 0px 6px 6px;
  }
`

const Name = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-top: 12px;
`

const Container = styled.div<{ $expanded: boolean }>`
  margin: 1rem 1.6rem;
  max-height: 0;
  height: auto;
  transition: all 0.5s;
  overflow: hidden;

  ${Grid.Row} {
    padding: 1rem 0;
  }

  ${({ $expanded }) =>
    $expanded &&
    css`
      max-height: 500px;
    `}
`

const Title = styled.div`
  font-size: 1.2rem;
  line-height: 2.2rem;
  text-align: center;
  color: #000000;
`

const Wrapper = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-height: 100%;
  max-width: 440px;
  min-height: 90px;
  width: 100%;
  z-index: 9101;
  overflow-y: auto;

  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`

const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem 1rem 0 1rem;
`

const Section = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 33.3%;
  cursor: pointer;
  position: relative;
  z-index: 10;

  &::before {
    content: ' ';
    position: absolute;
    border-radius: 50%;
    top: -7px;
    width: 50px;
    height: 50px;
    background: #fff975;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;
  }

  ${({ $active }) =>
    $active &&
    css`
      &::before {
        opacity: 1;
      }
    `}
  &:hover {
    &::before {
      opacity: 1;
    }
  }
`

const SectionTitle = styled.div<{ $expanded: boolean }>`
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
  color: #000000;
  padding-top: 0.8rem;
  max-height: 0;
  height: auto;
  transition: all 0.5s;
  overflow: hidden;

  ${({ $expanded }) =>
    $expanded &&
    css`
      max-height: 30px;
    `}
`
