import { loadTimestamp } from '@/App'
import { API, BASE_DOMAIN } from '@/api'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useUserStore } from '@/stores/userStore'
import { animated, useSpring } from '@react-spring/konva'
import { easePoly } from 'd3-ease'
import { isToday, parseISO } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Group, Rect, Text } from 'react-konva'
import useImage from 'use-image'
import { getMapCoord } from '../polygon/Polygon'

export const defaultMapPointImage = {
  owner_id: '15',
  image_id: '2d95a0df350c4c2dae7ab2c0c03bf337',
  extension: 'png'
}

export const getImageLink = (image) => {
  if (!image || !image.owner_id) return ''
  return `${BASE_DOMAIN}media/metablock/${image.owner_id}/${image.image_id}.SW100H100!default.${image.extension}?v=${loadTimestamp}`
}

const useMapAvatar = (bookingData, node) => {
  // if (bookingData) {
  //   console.log(bookingData, node)
  // }
  const imgSrc = bookingData
    ? API.user.getAvatar({
        userId: Number(bookingData.user_id),
        height: 100,
        width: 100,
        cache: bookingData['user_uts']
      })
    : getImageLink(node?.icon) || getImageLink(defaultMapPointImage)
  const [pointImg] = useImage(imgSrc)

  return { image: pointImg }
}

export const isBookable = (data) => {
  if (!data) return false
  const plugin: any = Object.values(data).find((obj: any) => obj?.bookable)
  if (!plugin) return false
  return plugin.bookable
}

export const isBookableForMe = (data, categories) => {
  if (!data) return false
  const plugin: any = Object.values(data).find((obj: any) => obj?.bookable)
  if (!plugin) return false
  const category = plugin.category
  const bookable = plugin.bookable
  const isAvailableForCategory = categories.includes(category)
  const isAvailableForBooking =
    isAvailableForCategory === true && bookable == true

  return isAvailableForBooking
}

const defaultNode = {
  background: '#ffffff',
  border: '#000000',
  radius: 50,
  uid: 'ae4c38a5a5994d8082029b51370111a3',
  name: 'Сервер'
}

const Point = ({
  point,
  options,
  nodes,
  bookings,
  springs,
  opacity,
  hasActivePermanent
}) => {
  const categories = useUserStore((state) => state.categories)
  const textRef = useRef<any>(null)
  const groupRef = useRef<any>(null)

  const { id, x, y, name, type_uid, plugin_data, fields } = point
  const isBookingFree = useMemo(
    () => fields.find((v) => v.name == '#bookingfree')?.value,
    [fields]
  )

  const availableForBooking = !hasActivePermanent && isBookingFree

  const { labelSize, fontSize, color, borderWidth, wrapText } = options

  const bookingData = useMemo(
    () => bookings?.find((book) => book.point_id == id),
    [bookings, id]
  )
  const avaliable = useMemo(
    () => isBookableForMe(plugin_data, categories),
    [plugin_data, categories]
  )
  const isNow = bookingData && isToday(parseISO(bookingData.start + '.000Z'))
  const seat = useGlobalStore((state) => state.seat)
  const current = useMemo(() => seat == id, [seat, id])
  const [hover, setHover] = useState(false)

  const node = nodes[type_uid] || defaultNode
  const username = bookingData?.display
    ? bookingData.display.split(' ').slice(0, 2).join(' ')
    : null
  const text = username || name
  const displayText = wrapText ? text.split(' ').join('\n') : text

  const isDepartment = useMemo(() => {
    const data = node.plugin_data

    if (!data) return null

    const fieldsObj: any = Object.values(data).find((obj: any) => obj['fields'])
    const fields = fieldsObj?.fields || null

    if (!fields) return null

    return fields.find((field) => field.name.includes('#number'))
  }, [node])

  const department = useMemo(() => {
    const data = point.plugin_data

    if (!data || !isDepartment) return null

    const pluginData: any = Object.values(data).find(
      (val: any) => val['field_' + isDepartment.id]
    )

    if (!pluginData) return null

    return pluginData['field_' + isDepartment.id]
  }, [point, isDepartment])

  const [coord, setCoord] = useState({ x: 0, y: 0, pX: 0 })
  const { image } = useMapAvatar(bookingData, node)

  const setSeat = useGlobalStore((state) => state.setSeat)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const setSection = useGlobalStore((state) => state.setSection)
  const [width, height] = useMapStore((state) => state.size)
  const setTooltip = useMapStore((state) => state.setTooltip)
  const zoomSeat = useMapStore((state) => state.zoomSeatPrev)

  const onSelect = useCallback(() => {
    setSeat(Number(point.id))
    if (bookingData) {
      setSeatEmployee(Number(bookingData.user_id))
      setSection('user')
    } else {
      setSeatEmployee(null)
      setSection('seat')
    }
  }, [setSeat, setSeatEmployee, bookingData, point])

  const onMouseEnterHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container()

    if (container) {
      container.style.cursor = 'pointer'
    }
  }, [])

  const onMouseLeaveHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container()

    if (container) {
      container.style.cursor = 'default'
    }
  }, [])

  const onGroupMouseEnterHandler = React.useCallback(() => {
    setTooltip(displayText)
    setHover(true)
  }, [setTooltip, displayText])

  const onGroupMouseLeaveHandler = React.useCallback(() => {
    setTooltip(null)
    setHover(false)
  }, [setTooltip])

  useEffect(() => {
    if (width > 0 && height > 0) {
      const pointX =
        -textRef.current?.textWidth / 2 + (width * labelSize) / 2 ||
        (width * labelSize) / 2
      setCoord({
        x: getMapCoord(width, x),
        y: getMapCoord(height, y),
        pX: Number(pointX)
      })
    }
  }, [textRef.current, image, width, height])

  useEffect(() => {
    if (!groupRef.current) return
    groupRef.current.on('mouseenter', onGroupMouseEnterHandler)
    groupRef.current.on('mouseleave', onGroupMouseLeaveHandler)
  }, [groupRef.current])

  return (
    <Group
      x={coord.x}
      y={coord.y}
      offsetX={(width * labelSize) / 2}
      offsetY={(width * labelSize) / 2}
      onClick={onSelect}
      onTap={onSelect}
      listening={true}
    >
      <animated.Group
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        // opacity={avaliable ? 1 : 0.3}
        id={'point' + point.id}
        ref={groupRef}
        align="center"
        {...opacity}
      >
        <animated.Rect
          width={width * labelSize}
          height={width * labelSize}
          stroke={availableForBooking ? 'red' : isNow ? 'yellow' : node?.border}
          // stroke={isNow ? 'yellow' : node?.border}
          strokeWidth={fontSize * borderWidth}
          fill={department ? '#fff' : node?.background}
          cornerRadius={width * labelSize * (node?.radius / 100)}
          shadowForStrokeEnabled={false}
          perfectDrawEnabled={false}
          listening={avaliable}
          {...springs}
        />
        {!department && image && (
          <Rect
            width={width * labelSize}
            height={width * labelSize}
            cornerRadius={width * labelSize * (node?.radius / 100)}
            fillPatternImage={image}
            fillPatternScaleX={(width * labelSize) / image?.width}
            fillPatternScaleY={(width * labelSize) / image?.width}
            shadowForStrokeEnabled={false}
            perfectDrawEnabled={false}
          />
        )}
        {department && (
          <Text
            text={department}
            align="center"
            verticalAlign="middle"
            fontSize={fontSize}
            fill={color}
            width={width * labelSize}
            height={width * labelSize}
            perfectDrawEnabled={false}
          />
        )}
        {isNow && (
          <Rect
            width={width * labelSize}
            height={width * labelSize}
            cornerRadius={width * labelSize * (node?.radius / 100)}
            fill={'yellow'}
            opacity={0.25}
            shadowForStrokeEnabled={false}
            perfectDrawEnabled={false}
          />
        )}
      </animated.Group>
      <Group
        onMouseEnter={(e) => {
          onGroupMouseEnterHandler()
          onMouseEnterHandler(e)
        }}
        onMouseLeave={(e) => {
          onGroupMouseLeaveHandler()
          onMouseLeaveHandler(e)
        }}
        y={width * 1.1 * labelSize}
        x={coord.pX - (textRef.current?.width() * 0.2) / 2}
      >
        <Rect
          width={textRef.current?.width() + textRef.current?.width() * 0.2}
          height={textRef.current?.height() + textRef.current?.width() * 0.1}
          fill={
            current || hover ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)'
          }
          cornerRadius={4}
          shadowForStrokeEnabled={false}
          perfectDrawEnabled={false}
          listening={true}
        />
        <Text
          ref={textRef}
          text={displayText}
          align="center"
          verticalAlign="middle"
          fontSize={fontSize}
          fill={color}
          x={(textRef.current?.width() * 0.2) / 2}
          y={(textRef.current?.width() * 0.1) / 2}
          listening={true}
          perfectDrawEnabled={false}
        />
      </Group>
    </Group>
  )
}

export const AnimatedPoint: React.FC<any> = React.memo((props) => {
  const { id, point, nodes } = props
  const { x, y, name, type_uid, plugin_data } = point
  const node = nodes[type_uid] || defaultNode

  const seat = useGlobalStore((state) => state.seat)
  const currentSeat = useMemo(() => seat == id, [seat, id])
  const zoomSeatPrev = useMapStore((state) => state.zoomSeatPrev)
  const setZoomSeatPrev = useMapStore((state) => state.setZoomSeatPrev)

  const animation = [
    {
      opacity: 0.2,
      stroke: 'rgba(255, 120, 126, 1)'
    },
    {
      opacity: 1,
      stroke: 'rgba(255, 120, 126, 1)'
    },
    {
      opacity: 0.2,
      stroke: 'rgba(255, 120, 126, 1)'
    },
    {
      opacity: 1,
      stroke: 'rgba(255, 120, 126, 1)'
    }
  ]

  const opacityAnimation = [
    {
      opacity: 0.2
    },
    {
      opacity: 1
    },
    {
      opacity: 0.2
    },
    {
      opacity: 1
    }
  ]

  const [springs, api] = useSpring(() => ({
    from: { opacity: 1, stroke: node?.border },
    to: timeline,
    config: {
      easing: easePoly.exponent(2),
      duration: 250
    }
  }))

  const [image, imageApi] = useSpring(() => ({
    from: { opacity: 1 },
    to: timeline,
    config: {
      easing: easePoly.exponent(2),
      duration: 250
    }
  }))

  const timeline = [...Array(5)].reduce((acc) => acc.concat(animation), [])
  const timelineOpacity = [...Array(5)].reduce(
    (acc) => acc.concat(opacityAnimation),
    []
  )

  useEffect(() => {
    const current = seat == id

    if (current) {
      api.start({
        from: { opacity: 1, stroke: node?.border },
        to: timeline,
        onRest: function () {
          if (zoomSeatPrev === id) {
            setZoomSeatPrev(null)
          }
        }
      })
      imageApi.start({
        from: { opacity: 1 },
        to: timelineOpacity
      })
    } else {
      api.start({
        from: { opacity: 1, stroke: node?.border },
        to: { opacity: 1, stroke: node?.border }
      })
      api.stop()

      imageApi.start({
        from: { opacity: 1 },
        to: { opacity: 1 }
      })
      imageApi.stop()
    }
  }, [seat])

  return (
    <Point
      {...props}
      springs={zoomSeatPrev == id ? springs : image}
      opacity={image}
    />
  )
})

AnimatedPoint.whyDidYouRender = true

export default React.memo(Point)
