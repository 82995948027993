import PlusIcon from '@/components/icons/PlusIcon'
import { translate } from '@/i18n'
import { DatePickerField } from '@/ui/components/Field/DatePickerField'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import Grid from '@/ui/components/Grid'
import media from '@/ui/media'
import {
  addDays,
  addMinutes,
  isAfter,
  isBefore,
  isEqual,
  setHours,
  setMinutes,
  startOfHour
} from 'date-fns'
import { Field, useFormikContext } from 'formik'
import styled from 'styled-components'

const BookingInterval = ({ name, interval, index }) => {
  const { setFieldValue } = useFormikContext()
  const start = interval.start ? new Date(interval.start) : null
  const maxDate = start ? addDays(start, 1) : null

  const filterTime = (time: any) => {
    if (!start) return true
    const selectedDate = new Date(start)

    const startTime = new Date(selectedDate)
    const endTime = new Date(selectedDate)
    endTime.setDate(selectedDate.getDate() + 1)

    // Убедитесь, что выбранное время находится между начальной датой и датой + 24 часа
    return time >= startTime && time <= endTime
  }

  return (
    <IntervalContainer key={'dates ' + index}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <IntervalGrid>
            <IntervalBox>
              <FormLabel>{translate('start-datetime')}</FormLabel>
              <Field
                name={`${name}[${index}].start`}
                component={DatePickerField}
                $fullWidth
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                minTime={startOfHour(new Date())}
                minDate={new Date()}
                maxTime={setMinutes(setHours(new Date(), 23), 59)}
                onChange={(val) => {
                  const date = startOfHour(new Date())

                  if (isBefore(val, date)) {
                    setFieldValue(`${name}[${index}].start`, date)
                  } else {
                    setFieldValue(`${name}[${index}].start`, val)
                  }
                  setFieldValue(`${name}[${index}].end`, null)
                }}
              />
            </IntervalBox>
            <IntervalBox>
              <FormLabel>{translate('end-datetime')}</FormLabel>
              <Field
                $fullWidth
                name={`${name}[${index}].end`}
                component={DatePickerField}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                disabled={!start}
                minDate={start}
                maxDate={maxDate}
                filterTime={filterTime}
                onChange={(val) => {
                  if (
                    interval.start &&
                    val &&
                    (isEqual(val, interval.start) ||
                      !isAfter(val, interval.start))
                  ) {
                    setFieldValue(
                      `${name}[${index}].end`,
                      addMinutes(interval.start, 30)
                    )
                  } else {
                    setFieldValue(`${name}[${index}].end`, val)
                  }
                }}
              />
            </IntervalBox>
          </IntervalGrid>
        </Grid>
      </Grid>
    </IntervalContainer>
  )
}

export const NoBookingIntervals = ({ helpers }) => (
  <Grid container>
    <Grid item xs={12} md={12}>
      <AddButton
        type="button"
        onClick={() => helpers.push({ start: '', end: '' })}
      >
        <BtnWrapper as="div">
          <PlusIcon color="#fff" />
        </BtnWrapper>
        <BtnText>{translate('add-interval')}</BtnText>
      </AddButton>
    </Grid>
  </Grid>
)

export default BookingInterval

const IntervalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
`

const IntervalContainer = styled.div`
  display: flex;
  width: 100%;
`

const IntervalBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const BtnWrapper = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background: #079dac;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`

const BtnText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  margin-left: 12px;
  cursor: pointer;
`

const AddButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;

  ${media.sm`
    border: 1px solid #079DAC;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
  `}
`
