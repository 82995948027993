import { useState } from "react"
import styled, { css } from "styled-components"
import { ElevatorIcon } from "./ElevatorIcon"
import { Floor } from "./Floor"
import { useElevator } from "./use-elevator"

export const Elevator = () => {
    const [show, setShow] = useState(true)
    const { isActive, floors, building, isLoading } = useElevator()

    if (!isActive) return <ElevatorWrapper />

    return (
        <ElevatorWrapper>
            <FloorsWrapper $active={show}>
                {floors.map((floor, idx) => <Floor key={idx} item={floor} />)}
            </FloorsWrapper>
            <ElevatorButton $active={show} onClick={() => setShow(!show)}>
                <ElevatorIcon />
            </ElevatorButton>
        </ElevatorWrapper>
    )
}


const FloorsWrapper = styled.div<{ $active: boolean }>`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    ${props => props.$active ? css`
        max-height: 900px;
    ` : css`
        max-height: 0;
    `}
`

const ElevatorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* align-items: center; */
    justify-content: center;
`
const ElevatorButton = styled.div<{ $active: boolean }>`
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.3s;

    background: #079DAC;
    border: 1px solid #079DAC;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    cursor: pointer;

    ${props => props.$active ? css`
        background: #fff;
        border: 1px solid #fff;
        color: #079DAC;
    ` : css`
        background: #079DAC;
        border: 1px solid #079DAC;
        color: #fff;
    `}

    &:hover {
        opacity: 0.7;
    }

    &:active {
        transform: scale(0.8);
    }
`