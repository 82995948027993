import { StatusResponseEnum } from '@/api'
import { BookingItem } from '@/api/bookings'
import { MomentType, useBookings } from '@/api/hooks/useBookings'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import { BookingService } from '@/api/services/booking.service'
import { RuntimeFieldData } from '@/api/services/user.service'
import { confirmDialog } from '@/components/ConfirmDialog'
import { Grid } from '@/components/Grid'
import Toolbar from '@/components/Toolbar/Toolbar'
import { DeleteIcon, EditIcon } from '@/components/icons'
import PointIcon from '@/components/icons/PointIcon'
import { useBookingsSearch } from '@/components/layout/Sidebar/useBookingsSearch'
import { bookingDialog } from '@/components/shared/booking/form/BookingModal'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import useResponsive from '@/hooks/useResponsive'
import useSorting from '@/hooks/useSorting'
import { translate } from '@/i18n'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useProjectStore } from '@/stores/projectStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { Input } from '@/ui/components/Field/Input'
import Pagination from '@/ui/components/Pagination/Pagination'
import { extractGaps } from '@/utils/helpers/dates.helpers'
import { addMinutes, format, isToday, parseISO, startOfWeek } from 'date-fns'
import debounce from 'lodash/debounce'
import orderBy from 'lodash/orderBy'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import styled, { css } from 'styled-components'
import MobileBookingItem from './MobileBookingItem'
import Sidebar from './Sidebar'

const getFieldName = (fields, sort) => {
  if (sort === 'place') return 'name'
  if (sort === 'user') return 'display'

  const field = fields.find((f) => f.uid == sort)

  if (field) {
    return field.label || null
  }

  return null
}

const getClientPageBookings = (bookings, page, isSearch, itemsPerPage = 1) =>
  isSearch
    ? bookings.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    : bookings.slice(0, itemsPerPage)

const Bookings = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [clientPage, setClientPage] = useState<number>(1)
  const { isDesktop } = useResponsive()
  const [placeType, setPlaceType] = useState<string>('')
  // const [bookingType, setBookingType] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [place, setPlace] = useState<string>('')
  const [moment, setMoment] = useState<MomentType>('future-current')
  const { sort, direction, handleSort } = useSorting()
  const intl = useIntl()

  const { data } = useBookings({
    page: currentPage,
    perPage: 20,
    moment,
    // day,
    // my: user?.display,
    name,
    // user: name,
    place,
    placeType,
    sort: sort || 'user',
    direction
  })

  const { data: search } = useBookingsSearch(name)

  const handleNameChange = (e) => setName(e?.target?.value)
  const handlePlaceChange = (e) => setPlace(e?.target?.value)

  const debouncedNameResponse = useMemo(() => {
    return debounce(handleNameChange, 500)
  }, [])

  useEffect(() => {
    return () => debouncedNameResponse.cancel()
  }, [])

  const debouncedPlaceResponse = useMemo(() => {
    return debounce(handlePlaceChange, 500)
  }, [])

  useEffect(() => {
    return () => debouncedPlaceResponse.cancel()
  }, [])

  const role = useUserStore((state) => state.role)
  const isAdmin = role === RolesEnum.Admin

  const { data: userFields } = useUserCardFields()

  const fields = useMemo(
    () => userFields?.fields.filter((v) => v.alias !== 'booking_category'),
    [userFields]
  )

  const isSearch = !!name && moment === 'constant'

  const bookings = !isSearch
    ? data?.items || []
    : orderBy(
        search,
        [getFieldName(fields, sort)],
        [direction ? 'desc' : 'asc']
      ) || []

  return (
    <Sidebar maxWidth="100%">
      <Sidebar.Header
        showAdd
        onAdd={isAdmin ? () => bookingDialog({}) : undefined}
        title="bookings"
      />

      <Toolbar>
        <Toolbar.Item xs={6} md={6}>
          <Toolbar.Label>{translate('search-bookings-by-user')}</Toolbar.Label>
          <Input
            $fullWidth
            placeholder={intl.formatMessage({ id: 'search' })}
            onChange={debouncedNameResponse}
          />
        </Toolbar.Item>
        <Toolbar.Item xs={6} md={6}>
          {isSearch && (
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingTop: 16
              }}
            >
              Результатов поиска: {bookings?.length}
            </div>
          )}
        </Toolbar.Item>

        {/*{isAdmin && (*/}
        {/*  <Toolbar.Item xs={6} md={6}>*/}
        {/*    <Toolbar.Label>*/}
        {/*      {translate('search-bookings-by-object')}*/}
        {/*    </Toolbar.Label>*/}
        {/*    <Input*/}
        {/*      $fullWidth*/}
        {/*      placeholder={intl.formatMessage({ id: 'search' })}*/}
        {/*      onChange={debouncedPlaceResponse}*/}
        {/*    />*/}
        {/*  </Toolbar.Item>*/}
        {/*)}*/}

        {/* <Toolbar.Item xs={6} md={4}>
                        <Toolbar.Label>{translate('object-type')}</Toolbar.Label>
                        <SelectInput $fullWidth placeholder="Выберите тип места" value={placeType} onChange={handlePlaceTypeChange}>
                            <option value="">{translate('all')}</option>
                            {nodes?.map(node => <option key={node.uid} value={node.uid}>{node.name}</option>)}
                        </SelectInput>
                    </Toolbar.Item> */}
        {/* <Toolbar.Item xs={6} md={3}>
                        <Toolbar.Label>Тип брони</Toolbar.Label>
                        <SelectInput $fullWidth placeholder="Выберите тип места"  value={bookingType} onChange={handleBookingTypeChange}>
                            <option value="0">Все</option>
                            <option value="1">Обычная</option>
                            <option value="2">Еженедельная</option>
                            <option value="3">Постоянная</option>
                        </SelectInput>
                    </Toolbar.Item> */}
      </Toolbar>

      <Sections>
        <SectionItem moment={moment} name="constant" setActive={setMoment}>
          {translate('current-bookings')}
        </SectionItem>
        <SectionItem moment={moment} name="past" setActive={setMoment}>
          {translate('archive-bookings')}
        </SectionItem>
      </Sections>

      <Grid>
        {isDesktop && (
          <Table>
            <thead>
              <tr>
                <TableCell
                  onClick={handleSort.bind(null, 'user')}
                  $active={sort === 'user'}
                  $direction={direction}
                >
                  {translate('full-name')}
                </TableCell>
                <TableCell
                  onClick={handleSort.bind(null, 'place')}
                  $active={sort === 'place'}
                  $direction={direction}
                >
                  {translate('location')}
                </TableCell>
                <th />
                {/*<TableCell>{translate('parent-location')}</TableCell>*/}
                {fields?.map((prop) => (
                  <TableCell
                    key={prop.uid}
                    onClick={handleSort.bind(null, prop.uid)}
                    $active={sort === prop.uid}
                    $direction={direction}
                  >
                    {prop.label}
                  </TableCell>
                ))}
              </tr>
            </thead>
            <tbody>
              {getClientPageBookings(bookings, clientPage, isSearch, 20).map(
                (item) => (
                  <ElementItem
                    key={item.id}
                    isAdmin={isAdmin}
                    item={item}
                    fields={fields || []}
                  />
                )
              )}
            </tbody>
          </Table>
        )}

        {!isDesktop && !data && <div>Не найдено</div>}

        {!isDesktop &&
          getClientPageBookings(bookings, clientPage, isSearch, 20).map(
            (item) => (
              <ElementItem
                key={item.id}
                isAdmin={isAdmin}
                item={item}
                fields={fields || []}
              />
            )
          )}
      </Grid>

      {isSearch ? (
        <Pagination
          total={bookings.length || 0}
          currentPage={clientPage}
          itemsPerPage={20}
          handlePageChange={setClientPage}
        />
      ) : (
        <Pagination
          total={data?.total || 0}
          currentPage={currentPage}
          itemsPerPage={20}
          handlePageChange={setCurrentPage}
        />
      )}
    </Sidebar>
  )
}

export default Bookings

function dynamicSort(property) {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

const Table = styled.table`
  td,
  th {
    padding: 4px 8px;
    text-align: left;
  }

  td {
    font-size: 12px;
    vertical-align: middle;
  }

  tr td:last-child {
    width: 1%;
    //white-space: nowrap;
  }
`

export const TableCell = styled.th<{ $active?: boolean; $direction?: number }>`
  cursor: pointer;
  position: relative;
  padding-right: 20px;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  &::after {
    opacity: 0;
    content: ' ';
    position: absolute;
    top: 50%;
    right: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='6' width='1.41421' height='8.48527' transform='rotate(45 6 0)' fill='%23fff' /%3E%3Crect x='7' y='11' width='1.41421' height='8.48527' transform='rotate(135 7 11)' fill='%23fff' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: translateY(-50%) rotate(-90deg);
    height: 12px;
    width: 7px;
    ${({ $active }) =>
      $active &&
      css`
        opacity: 1;
      `}
    ${({ $direction }) =>
      $direction &&
      css`
        transform: translateY(-50%) rotate(90deg);
      `}
  }
`

const SectionItem = ({ moment, name, setActive, children }) => {
  const handleOnchange = () => setActive(name)

  return (
    <SecItem $active={moment === name} onClick={handleOnchange}>
      {children}
    </SecItem>
  )
}

const ElementItem: React.FC<{
  item: BookingItem
  isAdmin: boolean
  fields: RuntimeFieldData[]
}> = ({ item, isAdmin, fields = [] }) => {
  const bookingType =
    item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant'
  const queryClient = useQueryClient()
  const { workspaceId, projectId } = useProject()
  const { enqueueToast } = useToast()
  const { isDesktop } = useResponsive()

  const setSection = useGlobalStore((state) => state.setSection)
  const setSeat = useGlobalStore((state) => state.setSeat)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
  const setZoomSeat = useMapStore((state) => state.setZoomSeat)
  const setZoomSeatPrev = useMapStore((state) => state.setZoomSeatPrev)

  const handleSeatView = (e) => {
    e.preventDefault()

    setActiveLayer(Number(item.parent_layer_id))
    setSeatEmployee(null)
    setSeat(Number(item.bookable_id))
    setZoomSeat(Number(item.bookable_id))
    setZoomSeatPrev(Number(item.bookable_id))

    setSection('user')
  }

  const nodes = useProjectStore((state) => state.nodes)

  const parent = useMemo(
    () => nodes.find((node) => node.id == Number(item.parent_layer_id)),
    [item]
  )
  const isNow = useMemo(() => isToday(parseISO(item.start + '.000Z')), [item])

  const intl = useIntl()

  const removeBooking = async () => {
    try {
      const response = await BookingService.removeBooking({
        workspaceId,
        projectId,
        bookingId: Number(item.id)
      })

      if (response && response.data.status === StatusResponseEnum.Success) {
        queryClient.refetchQueries(['bookings'])

        // remove cache data for map
        queryClient.refetchQueries('bookings_for_layer')

        enqueueToast(
          {
            title: intl.formatMessage({ id: 'success' }),
            message: intl.formatMessage({ id: 'booking-deleted' })
          },
          { variant: 'success' }
        )
      }
    } catch (e) {
      enqueueToast(
        {
          title: intl.formatMessage({ id: 'error' }),
          message: intl.formatMessage({ id: 'failed-to-delete-booking' })
        },
        { variant: 'error' }
      )
    }
  }

  const handleEdit = () => bookingDialog({ bookingId: Number(item.id) })
  const handleDelete = (e) => {
    return confirmDialog({
      title: intl.formatMessage({ id: 'delete-booking' }),
      message: intl.formatMessage({ id: 'delete-booking-confirm' }),
      onSubmit: removeBooking
    })
  }

  if (!isDesktop)
    return (
      <MobileBookingItem
        isNow={isNow}
        item={item}
        fio={item.user}
        name={item.name}
        type={translate(bookingType)}
        date={
          <BookingDate
            type={bookingType}
            gap={item.gap}
            date={{ start: item.start, end: item.end }}
          />
        }
        parentName={parent?.name}
        handleEdit={isAdmin ? handleEdit : null}
        handleDelete={isAdmin ? handleDelete : null}
        handleSeatView={handleSeatView}
        fields={fields}
      />
    )

  return (
    <tr>
      <td>{item.user}</td>
      <td>{item.name}</td>
      <td>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-around'
          }}
        >
          <Grid.Item>
            <a href="#" onClick={handleSeatView}>
              <PointIcon />
            </a>
          </Grid.Item>
          {isAdmin && (
            <>
              <ActionButton onClick={handleEdit}>
                <EditIcon />
              </ActionButton>
              <ActionButton onClick={handleDelete}>
                <DeleteIcon />
              </ActionButton>
            </>
          )}
        </div>
      </td>
      {/*<td>{parent?.name || ''}</td>*/}
      {fields.map((prop) => (
        <td key={prop.uid}>{item[prop.uid] || item[prop.label]}</td>
      ))}
    </tr>
  )
}

const BookingCol = styled(Grid.Item)`
  font-weight: 400;
  font-size: 12px;
  line-height: 32px;
`

const ActionButton = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const bookingTypeTranslate = {
  common: 'Обычная',
  recurrent: 'Еженедельная',
  constant: 'Постоянная'
}

type BookingDateProps = {
  type: 'common' | 'recurrent' | 'constant'
  gap: string
  date: {
    start: string
    end: string
  }
  inverse?: boolean
}

const d = new Date()
const offset = d.getTimezoneOffset()

export const BookingDate: React.FC<BookingDateProps> = ({
  type,
  date,
  gap,
  inverse = false
}) => {
  const [open, setOpen] = useState(false)

  if (type === 'recurrent') {
    const gapToSlots = Array.isArray(gap) ? gap.join(',') : gap
    const schedule = extractSchedule(gapToSlots.split(','))

    return (
      <div>
        <ScheduleWrapper
          $expandable={true}
          $expanded={open}
          $inverse={inverse}
          onClick={() => setOpen(!open)}
        >
          {`${format(
            parseISO(date.start + '.000Z'),
            'dd.MM.yyyy HH:mm'
          )} - ${format(parseISO(date.end + '.000Z'), 'dd.MM.yyyy HH:mm')}`}
        </ScheduleWrapper>
        <ScheduleContent $expanded={open}>
          {schedule.map((slot) => (
            <p key={slot.start.toISOString()}>
              <b>{weekdays[new Date(slot.start).getDay()]}</b>{' '}
              {format(addMinutes(slot.start, -offset), 'HH:mm')} -{' '}
              {format(addMinutes(slot.end, -offset), 'HH:mm')}
            </p>
          ))}
        </ScheduleContent>
      </div>
    )
  }

  return (
    <div>
      <ScheduleWrapper $inverse={inverse}>
        {format(parseISO(date.start + '.000Z'), 'dd.MM.yyyy HH:mm')}
        {type !== 'constant'
          ? ` - ${format(parseISO(date.end + '.000Z'), 'dd.MM.yyyy HH:mm')}`
          : ''}
      </ScheduleWrapper>
    </div>
  )
}

const weekdays = {
  1: 'Пн.',
  2: 'Вт.',
  3: 'Ср.',
  4: 'Чт.',
  5: 'Пт.',
  6: 'Сб.',
  0: 'Вс.'
}

const interval = 30 * 60 * 1000

export const extractSchedule = (gap: string[]) => {
  const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 })
  const gaps = extractGaps(gap)

  return gaps.map((slot) => ({
    start: new Date(weekStart.getTime() + 30 * 60 * 1000 * slot[0]),
    end: new Date(weekStart.getTime() + interval * Number(slot[1]) + interval)
  }))
}

const ScheduleWrapper = styled.div<{
  $expandable?: boolean
  $expanded?: boolean
  $inverse?: boolean
}>`
  padding-right: 20px;
  position: relative;

  ${({ $expandable, $expanded, $inverse }) =>
    $expandable &&
    css`
      cursor: pointer;

      &::after {
        content: ' ';
        position: absolute;
        background-image: ${$inverse
          ? `url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='1' width='1.41421' height='8.48527' transform='rotate(-45 0 1)' fill='%23000000'/%3E%3Crect x='11' width='1.41421' height='8.48527' transform='rotate(45 11 0)' fill='%23000000'/%3E%3C/svg%3E%0A")`
          : `url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='1' width='1.41421' height='8.48527' transform='rotate(-45 0 1)' fill='%23FDFDFD'/%3E%3Crect x='11' width='1.41421' height='8.48527' transform='rotate(45 11 0)' fill='%23FDFDFD'/%3E%3C/svg%3E%0A")`};
        width: 12px;
        height: 7px;
        background-size: cover;
        top: 12px;
        right: 5px;
        transition: all 0.5s;
        transform: ${$expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
      }
    `}
`
const ScheduleContent = styled.div<{ $expanded?: boolean }>`
  height: auto;
  max-height: 0px;
  margin-top: 8px;
  transition: all 0.5s;
  overflow: hidden;

  ${({ $expanded }) =>
    $expanded &&
    css`
      max-height: 200px;
    `}
  p {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
`

const Sections = styled.div`
  display: flex;
  margin-bottom: 8px;
  flex-shrink: 0;
  flex-wrap: wrap;
`

const SecItem = styled.div<{ $active?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #f8dc4d;
  cursor: pointer;

  ${({ $active }) =>
    $active
      ? css`
          border-bottom: 2px solid #f8dc4d;
          opacity: 1;
        `
      : css`
          opacity: 0.5;
          border-bottom: 2px solid transparent;
        `}
  &:hover {
    border-bottom: 2px solid #f8dc4d;
    opacity: 1;
  }

  &:not(:last-child) {
    margin-right: 24px;
  }
`
