// function extractDescendantIds(node) {
//     let descendantIds = [];

//     // Рекурсивная функция для извлечения идентификаторов потомков
//     function getDescendantsIds(node) {
//         // Проверяем, есть ли у текущего узла потомки
//         if (node.childs && node.childs.length > 0) {
//             // Проходим по потомкам
//             for (let child of node.childs) {
//                 // Добавляем идентификатор потомка в массив
//                 descendantIds.push(child.id);
//                 // Рекурсивно вызываем функцию для каждого потомка
//                 getDescendantsIds(child);
//             }
//         }
//     }

//     // Вызываем рекурсивную функцию для заданной вершины
//     getDescendantsIds(node);

//     return descendantIds;
// }

export function findParentAndFloorIds(projectTree, parentId, floors) {
    // Находим родителя по идентификатору
    const parentNode = findNodeById(projectTree, parentId);

    if (!parentNode) {
        // Если родитель не найден, возвращаем null
        return { floors, parent: null }
    }

    // Находим все идентификаторы потомков родителя
    const descendantIds = extractDescendantIds(parentNode.node);

    // Формируем объект с этажами и идентификаторами всех потомков
    const result = floors.map(floor => ({
            ...floor,
            childs: descendantIds[floor.layer] || [] // Используем идентификаторы всех потомков, если они есть, иначе пустой массив
        }))

    return { floors: result, parent: parentNode };
}

export function extractDescendantIds(node) {
    let descendantIds = {};

    // Рекурсивная функция для извлечения идентификаторов потомков
    function getDescendantsIds(node) {
        let ids: number[] = [];

        // Проверяем, есть ли у текущего узла потомки
        if (node.childs && node.childs.length > 0) {
            // Проходим по потомкам
            for (let child of node.childs) {
                // Добавляем идентификатор потомка в массив
                ids.push(child.id);
                // Рекурсивно вызываем функцию для каждого потомка
                const childIds = getDescendantsIds(child);
                // Добавляем идентификаторы потомков текущего узла
                ids = ids.concat(childIds);
            }
        }

        // Добавляем массив идентификаторов потомков текущего узла в объект
        descendantIds[node.id] = ids;

        return ids;
    }

    // Вызываем рекурсивную функцию для заданной вершины
    getDescendantsIds(node);

    return descendantIds;
}


export function findNodeById(nodes, id) {
    // Рекурсивная функция для поиска узла по идентификатору
    function findNode(node) {
        // Проверяем, соответствует ли текущий узел идентификатору
        if (node.id == id) {
            return node;
        }

        // Проверяем, есть ли у текущего узла потомки
        if (node.childs && node.childs.length > 0) {
            // Проходим по потомкам
            for (let child of node.childs) {
                // Вызываем функцию рекурсивно для каждого потомка
                const foundNode = findNode(child);
                // Если узел найден, возвращаем его
                if (foundNode) {
                    return foundNode;
                }
            }
        }

        // Если узел не найден, возвращаем null
        return null;
    }

    // Проходим по всем корневым узлам
    for (let node of nodes) {
        // Вызываем функцию для поиска узла по идентификатору
        const foundNode = findNode(node);
        // Если узел найден, возвращаем его
        if (foundNode) {
            return {
                node: foundNode,
                ids: Object.values(extractDescendantIds(foundNode)).flat()
            }
        }
    }

    // Если узел не найден, возвращаем null
    return null;
}
