import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import lensIcon from '@/images/lens.png';
import { SearchLayersIcon, SearchUsersIcon } from '../../../icons/SearchIcons';
import { ProjectService } from '@/api/services/project.service';
import { useProject } from '@/hooks/useProject';
import debounce from 'lodash/debounce';
import flattenDeep from 'lodash/flattenDeep';
import { useToast } from '@/components/shared/toast/useToast';
import SearchItem from './SearchItem';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import SearchIcon from '@/components/icons/SearchIcon';
import { useGlobalStore } from '@/stores/globalStore';
import { animated, useTransition } from 'react-spring';
import { easePoly } from 'd3-ease';
import { Portal } from 'react-portal';
import MobileSearch from './MobileSearch';
import { useProjectStore } from '@/stores/projectStore';
import { lowerCaseString } from '@/utils/helpers/string.helpers';
import { translate } from '@/i18n';
import { FormattedMessage } from 'react-intl';

export const filterResults = (term: string, results: any[]) => {
  const items = results
    .filter(result => result.name.toLowerCase().indexOf(term.toLowerCase()) + 1 > 0);

  let search: any[] = results;

  items.forEach(item => {
    // if (item.type === 'node') {
    //     search = results
    //         .filter(res => !(res.type === 'booking' && res.place.toLowerCase().indexOf(item.name.toLowerCase()) + 1 > 0))
    // }

    if (item.type === 'user') {
      search = results
        // .filter(res => !(res.type == 'node' && bookings.find(book => book.place_id == res.id)));
        .filter((res, _, arr) => {
          if (res.type === 'node') {
            const peoples = Object.keys(res).find(key => key.includes('peoples'));
            const peoplesObj = peoples ? JSON.parse(res[peoples]) : {};
            const person = arr.findIndex(ps => ps.type === 'user' && ps.id == peoplesObj?.user_id);

            return !person;
          }

          return true;
        });
    }
  });

  // if (!bookings) {
  //     search = search.filter(res => !(res.type === "node" && res.name.toLowerCase().indexOf(term.toLowerCase()) + 1 === 0))
  // }

  // search = search
  //     .filter(res => !(res.type == 'user' && res.name.toLowerCase().indexOf(term.toLowerCase()) + 1 === 0))

  search = search.filter(entity => filterUser(entity, term));

  // search = search
  //     .filter(res => {
  //         if (res.type == 'node') {
  //             return res.name.toLowerCase().indexOf(term.toLowerCase()) + 1 > 0
  //         }
  //         return true
  //     })

  return search;
  //
  // return search.filter(item => {
  //     const keys = Object.keys(item)
  //     const bookableKey = keys.find(key => key.includes('bookable'))
  //
  //     if (bookableKey) {
  //         const isBookable = item[bookableKey]
  //         return isBookable != '1'
  //     }
  //
  //     return true
  // })

};

const filterUser = (entity, term) => {
  try {
    const checkName = !(entity.type == 'user' && entity.name.toLowerCase().indexOf(term.toLowerCase()) + 1 === 0);
    const fields = extractFields(entity) || [];
    const checkFields = fields.filter(field => lowerCaseString(String(field.value)).includes(lowerCaseString(term)));

    return checkName || checkFields;
  } catch (e) {
    return false
  }
};

const extractFields = (user) => {
  const fieldName = Object.keys(user).find(prop => prop.includes('fields'));
  if (fieldName) {
    const fieldsObject = user[fieldName];
    if (!fieldsObject) return [];
    const fields = JSON.parse(fieldsObject);
    return fields;
  }
  return [];
};

const Search = () => {
  const { workspaceId, projectId } = useProject();
  const { enqueueToast } = useToast();
  const searchRef = useRef(null);

  const setSelector = useGlobalStore(state => state.setSelector);
  const fullMode = useProjectStore(state => state.fullMode);

  // local state
  const [bookings, setBookings] = useState(false);
  const [places, setPlaces] = useState(true);
  const [user, setUser] = useState(true);
  const [layers, setLayers] = useState(true);
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);


  useOnClickOutside(searchRef, () => setFocus(false));

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.search({
        workspaceId,
        projectId,
        term: String(search),
        point: places,
        user: fullMode ? user || bookings : false,
        layer: layers
      });

      if (response && response.data) {
        const { search_result } = response.data;
        const results: any[] = flattenDeep(search_result);

        const result = results.map(res => {
          if (res.type === 'user') {
            res.name = res.display;

            if (bookings) {
              const pathBookings = Object.keys(res).find(key => key.includes('locations')) || '';
              const userBookings = JSON.parse(res[pathBookings]) || [];

              const isCorrectSearchBook = lowerCaseString(res.name).includes(lowerCaseString(search));

              if (!isCorrectSearchBook && !user) return null;
              if (!isCorrectSearchBook) {
                return res;
              }

              const bookings = userBookings.map(booking => ({
                type: 'booking',
                user: res['display'],
                user_id: res['id'],
                name: res['display'],
                id: booking['booking_id'],
                type_uid: booking['place_type'],
                place: booking['place_name'],
                begin: booking['begin'],
                end: booking['end'],
                layer_id: booking['layer_id'],
                place_id: booking['place_id']
              }));

              if (!user) {
                return bookings;
              }


              return [res, ...bookings];

            }

            if (!user) return null;

            return res;
          }

          return res;
        }).filter(v => v);

        const searchResults = flattenDeep(result);

        setData(filterResults(search, searchResults));
      }
    } catch (e) {
      console.error(e)
      enqueueToast({ title: 'Ошибка', message: 'Не удалось загрузить данные поиска' }, { variant: 'error' });
    }
    setLoading(false);
  };

  const handleChange = (e) => setSearch(e?.target?.value);
  const handleClose = () => setSelector(null);

  const debouncedResponse = useMemo(() => {
    return debounce(handleChange, 500);
  }, []);

  useEffect(() => {
    if (search) {
      fetchData();
    }
  }, [search, layers, user, places, bookings]);

  useEffect(() => {
    return () => debouncedResponse.cancel();
  }, []);

  return (
    <Wrapper onFocus={() => setFocus(true)}>
      <SearchBox ref={searchRef}>
        <SearchWrapper onClick={handleClose}>
          <FiltersWrapper>
            <SearchIcon />
          </FiltersWrapper>
          <FormattedMessage id='search'>
            {placeholder => (
              <SearchInput placeholder={placeholder + '...'} onChange={debouncedResponse} />
            )}
          </FormattedMessage>
          <FiltersWrapper>
            {/* {fullMode && (
                            <FiltersItem>
                                <SearchBookingsIcon active={bookings} onClick={() => setBookings(!bookings)} />
                            </FiltersItem>
                        )} */}
            {/* <FiltersItem>
                            <SearchPlacesIcon active={places} onClick={() => setPlaces(!places)} />
                        </FiltersItem> */}
            {fullMode && (
              <FiltersItem>
                <SearchUsersIcon active={user} onClick={() => setUser(!user)} />
              </FiltersItem>
            )}
            <FiltersItem>
              <SearchLayersIcon active={layers} onClick={() => setLayers(!layers)} />
            </FiltersItem>
          </FiltersWrapper>
        </SearchWrapper>

        {search && focus && (
          <SearchResults>
            {data.length ? data.map(item => <SearchItem key={item.name + item.id} handleSearchClose={null}
                                                        data={item} />) :
              <NotFound>{translate('no-results')}</NotFound>}
            {isLoading && <NotFound>{translate('loading')}....</NotFound>}
          </SearchResults>
        )}
      </SearchBox>
    </Wrapper>
  );
};

export default Search;

export const SearchMobileButon = (props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<any>();

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: open,
    config: {
      duration: 300,
      easing: easePoly.exponent(2)
    }
  });

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <ShowContainer>
      <MobileSearchWrapper onClick={() => setOpen(!open)}>
        <SearchIcon />
      </MobileSearchWrapper>
      {transitions(
        (style, item) => item && (
          <Portal>
            <FixedElement>
              <ShowPicker
                style={style}
              >
                <MSearchWrapper ref={ref}>
                  <MobileSearch handleSearchClose={handleClose} />
                </MSearchWrapper>
              </ShowPicker>
            </FixedElement>
          </Portal>
        )
      )}
    </ShowContainer>
  );
};

const FixedElement = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 75px;
  z-index: 1111;
`;

const ShowPicker = styled(animated.div)`
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  padding: 10px;
`;

const MSearchWrapper = styled.div`
  margin-top: 20px;
  height: 100%;
`;

const ShowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SearchButton = (props) => {
  return (
    <SearchButtonBox {...props}>
      <img src={lensIcon} alt='search lens' />
    </SearchButtonBox>
  );
};

const MobileSearchWrapper = styled.div`
  height: 40px;
  background: #FFFFFF;
  border-radius: 8px;
  width: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
`;

const FiltersItem = styled.div`
  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const NotFound = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

const SearchResults = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  position: absolute;
  top: 100%;
  margin-top: 6px;
  width: 100%;
  max-height: 500px;
  height: auto;
  box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
`;

const SearchWrapper = styled.div`
  height: 40px;
  background: #FFFFFF;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  overflow: hidden;
  padding: 0px 15px;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  height: 100%;
  /* width: 100%; */

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3.2rem;
  color: #000000;
  padding: 1rem;
`;

const SearchBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  input {
    margin-right: 0.8rem;
  }
`;

const SearchButtonBox = styled.button`
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;