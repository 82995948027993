import { useMemo } from 'react'
import { useBookings } from './useBookings'
import { useMe } from './useMe'

export const useMyBookings = () => {
  const { user } = useMe()
  const { data } = useBookings({
    page: 1,
    perPage: 1000,
    moment: 'future-current',
    user: user?.user_id
  })

  const permanentBookings = useMemo(
    () =>
      data?.items.filter(
        (booking) => booking.rec == '0' && booking.end == null
      ) || [],
    [data?.items]
  )
  const regularBookings = useMemo(
    () =>
      data?.items.filter(
        (booking) => booking.rec == '0' && booking.end != null
      ) || [],
    [data?.items]
  )
  const hasActivePermanent = useMemo(
    () => permanentBookings.length > 0,
    [permanentBookings]
  )

  if (!user?.user_id) {
    return {
      permanent: [],
      regular: [],
      hasActivePermanent: false
    }
  }

  return {
    permanent: permanentBookings,
    regular: regularBookings,
    hasActivePermanent
  }
}
