import { useGlobalStore } from '@/stores/globalStore'
import { useEffect } from 'react'
import { useSettingsSelector } from './settings/use-settings-selector'


export function useProject() {
  const project = useGlobalStore((state) => state.project)
  const setProject = useGlobalStore((state) => state.setProject)

  const isTst = location.pathname.includes('tst')
  const isHeadquarters = location.pathname.includes('headquarters')
  const query = new URLSearchParams(location.search)


  const tst = useSettingsSelector(settings => settings.tst)
  const headquarters = useSettingsSelector(settings => settings.headquarters)
  const workspaceId = useSettingsSelector(settings => settings.workspaceId)
  const projectId = useSettingsSelector(settings => settings.projectId)



  useEffect(() => {
    // console.log(workspaceId, projectId, tst, headquarters);

    if (!workspaceId || !projectId) {
      return
    }


    if (isTst || query.get('redirect')?.includes('tst')) {
      setProject({
        projectId: Number(tst!.projectId),
        workspaceId: Number(tst!.workspaceId),
        type: 'tst'
      })
    } else if (isHeadquarters || query.get('redirect')?.includes('headquarters')) {
      setProject({
        projectId: Number(headquarters!.projectId),
        workspaceId: Number(headquarters!.workspaceId),
        type: 'headquarters'
      })
    } else {
      setProject({
        projectId: Number(projectId),
        workspaceId: Number(workspaceId),
        type: 'main'
      })
    }
  }, [isTst, isHeadquarters])

  return project
}
