export const ElevatorIcon = () => {
    return (
        <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.87979 17.1791C7.95799 17.1791 7.21143 17.9256 7.21143 18.8469C7.21143 19.7674 7.95799 20.514 8.87979 20.514C9.80033 20.514 10.547 19.7674 10.547 18.8469C10.5469 17.9257 9.80026 17.1791 8.87979 17.1791Z" fill="currentColor" />
            <path d="M19.0492 17.1791C18.1286 17.1791 17.3813 17.9256 17.3813 18.8469C17.3813 19.7674 18.1286 20.514 19.0492 20.514C19.9703 20.514 20.7169 19.7674 20.7169 18.8469C20.7169 17.9257 19.9703 17.1791 19.0492 17.1791Z" fill="currentColor" />
            <path d="M24.8836 10.5295H3.04383C1.36531 10.5295 0 11.8949 0 13.5733V36.9561C0 38.6346 1.36531 40 3.04383 40H24.8836C26.5627 40 27.9274 38.6346 27.9274 36.9561V13.5733C27.9274 11.8949 26.5627 10.5295 24.8836 10.5295ZM8.52055 38.0789C8.52563 38.0405 8.5429 38.0071 8.5429 37.9674L8.87938 30.3342L9.21516 37.9674C9.21516 38.0071 9.23251 38.0405 9.23759 38.0789H8.52055ZM18.691 38.0789C18.696 38.0405 18.7134 38.0071 18.7134 37.9674L19.0492 30.3342L19.385 37.9674C19.385 38.0071 19.4024 38.0405 19.4077 38.0789H18.691ZM26.0063 36.9561C26.0063 37.5755 25.5029 38.0789 24.8836 38.0789H21.055C21.06 38.0405 21.0774 38.0071 21.0774 37.9674L21.4113 25.5514L21.7174 28.8863C21.7345 29.2448 22.0388 29.5214 22.3967 29.5037C22.7546 29.4867 23.0311 29.1831 23.0141 28.8245V23.3341C23.0141 21.0363 20.2336 20.9235 19.0491 20.9235C17.8652 20.9235 15.0848 21.0363 15.0848 23.3341V28.8245C15.0677 29.183 15.3443 29.4867 15.7022 29.5037C16.0601 29.5214 16.3638 29.2448 16.3814 28.8863L16.6877 25.5514L17.021 37.9674C17.021 38.0071 17.0383 38.0405 17.0437 38.0789H10.8849C10.8902 38.0405 10.9075 38.0071 10.9075 37.9674L11.2414 25.5514L11.547 28.8863C11.564 29.2448 11.8684 29.5214 12.2263 29.5037C12.5842 29.4867 12.8607 29.1831 12.8437 28.8245V23.3341C12.8437 21.0363 10.0633 20.9235 8.87938 20.9235C7.69548 20.9235 4.91438 21.0363 4.91438 23.3341V28.8245C4.89735 29.183 5.17391 29.4867 5.5318 29.5037C5.88969 29.5214 6.19399 29.2448 6.21102 28.8863L6.51727 25.5514L6.85118 37.9674C6.85118 38.0071 6.86852 38.0405 6.87352 38.0789H3.04375C2.425 38.0789 1.92094 37.5755 1.92094 36.9561V13.5733C1.92094 12.954 2.425 12.4506 3.04375 12.4506H24.8835C25.5028 12.4506 26.0063 12.954 26.0063 13.5733L26.0063 36.9561Z" fill="currentColor" />
            <path d="M16.3431 5.52688L18.6251 3.24485V8.37548C18.6251 8.8436 19.0044 9.22298 19.4724 9.22298C19.9406 9.22298 20.3199 8.84368 20.3199 8.37548V3.24485L22.6026 5.52688C22.7678 5.69266 22.9845 5.77516 23.2018 5.77516C23.4185 5.77516 23.636 5.69266 23.801 5.52688C24.1325 5.1961 24.1325 4.6593 23.801 4.32789L20.0717 0.599219L19.4725 0L18.8733 0.599219L15.1441 4.32789C14.8133 4.6593 14.8133 5.1961 15.1441 5.52688C15.4754 5.85766 16.0123 5.85766 16.3431 5.52688Z" fill="currentColor" />
            <path d="M8.25123 8.97463L8.85045 9.57385L9.44967 8.97463L13.1791 5.24525C13.5098 4.91447 13.5098 4.37767 13.1791 4.04689C12.8483 3.71611 12.3114 3.71611 11.9806 4.04689L9.69788 6.32893V1.19845C9.69788 0.730327 9.31866 0.350952 8.85053 0.350952C8.38241 0.350952 8.00311 0.730249 8.00311 1.19845V6.329L5.72108 4.04697C5.39029 3.71619 4.8535 3.71619 4.52201 4.04697C4.19123 4.37775 4.19123 4.91455 4.52201 5.24533L8.25123 8.97463Z" fill="currentColor" />
        </svg>
    )
}