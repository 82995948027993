import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { ProjectTree } from '@/api/project'
import TreeList, { TreeListStyles } from './TreeList'
import MoreIcon from '@/components/icons/MoreIcon'
import useHover from '@/hooks/useHover'
import ArrowIcon from '@/components/icons/ArrowIcon'
import { useGlobalStore } from '@/stores/globalStore'
import useResponsive from '@/hooks/useResponsive'
import { layerChangeEvent } from '@/components/layout/AppBar/search/SearchItem'

const TreeItem: React.FC<{ tree: ProjectTree; expand: boolean }> = ({
  tree,
  expand
}) => {
  const ref = useRef(null)
  const hover = useHover(ref)
  const { isMobile } = useResponsive()

  // data selectors
  const activeLayer = useGlobalStore((state) => state.activeLayer)
  const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
  const setLayerModal = useGlobalStore((state) => state.setLayerModal)
  const setSelector = useGlobalStore((state) => state.setSelector)

  const hasChildren = tree.childs && tree.childs.length
  const expandable = !!hasChildren
  const active = tree.id == activeLayer || hover

  // local state
  const [expanded, setExpanded] = useState<boolean>(expand)

  const handleExpand = (e) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }
  const handleAbout = (e) => {
    e.stopPropagation()
    setLayerModal(tree.id)
  }

  const handleLayerChange = () => {
    if (isMobile) {
      setSelector(null)
    }
    document.dispatchEvent(layerChangeEvent(tree.id))
    setActiveLayer(tree.id)
  }

  return (
    <ListItem>
      <ListName ref={ref} onClick={handleLayerChange} $active={active}>
        <NameWrapper>{tree.name}</NameWrapper>
        <ItemControls>
          {active && (
            <AboutButton onClick={handleAbout}>
              <MoreIcon />
            </AboutButton>
          )}
          {expandable && (
            <ExpandButton
              onClick={expandable ? handleExpand : undefined}
              $expanded={expanded}
            >
              <ArrowIcon color="#fff" />
            </ExpandButton>
          )}
        </ItemControls>
      </ListName>
      <TreeList $expanded={expanded}>
        {tree.childs.map((child) => (
          <TreeItem expand={false} tree={child} key={child.id} />
        ))}
      </TreeList>
    </ListItem>
  )
}

export default TreeItem

const ItemControls = styled.div`
  display: flex;
  align-items: center;
`

const ExpandButton = styled.button<{ $expanded?: boolean }>`
  outline: none;
  border: none;
  background: transparent;
  transform: rotate(180deg);
  transition: transform 0.2s ease-in;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  ${({ $expanded }) =>
    $expanded &&
    css`
      transform: rotate(90deg);
    `}
`

const ListName = styled.div<{ $active?: boolean }>`
  /* padding: 8px; */
  min-height: 40px;
  border-radius: 8px;
  color: ${(props) => props.theme.palette.text.primary};
  position: relative;
  display: flex;
  justify-content: space-between;

  &:hover {
    color: ${(props) => props.theme.palette.accent.main};
    text-decoration: underline;
  }

  ${({ $active }) =>
    $active &&
    css`
      color: ${(props) => props.theme.palette.accent.main};
      text-decoration: underline;
    `}
`

const ListItem = styled.li`
  ${TreeListStyles} /* padding-top: 0.8rem; */ position: relative;
  cursor: pointer;
  min-height: 42px;
`

const AboutButton = styled.button`
  outline: none;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #f8dc4d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  /* top: 12px; */
  right: 42px;
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 60px;
  user-select: none;
  max-width: 300px;
`
