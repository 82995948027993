import { useTree } from '@/api/hooks/useTree';
import BackIcon from '@/components/icons/BackIcon';
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector';
import { useGlobalStore } from '@/stores/globalStore';
import media from '@/ui/media';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import SearchBar from './search/SearchBar';
import { layerChangeEvent } from './search/SearchItem';
import UserBar from './user/UserBar';

const Appbar: React.FC = () => {
  return (
    <Wrapper>
      <GridWrapper>
        <div>
          <BackItem name="Вернуться" icon={BackIcon} />
        </div>
        <SearchBar />
      </GridWrapper>
      {/* <DateSelectorBar /> */}
      <Home />
      <UserBar />
    </Wrapper>
  )
}

export default Appbar

const GridWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 60px 1fr;
  gap: 16px;
`


const BackItem = ({ name, icon: Icon }) => {
  const { layers } = useTree()
  const { activeLayer, setActiveLayer } = useGlobalStore()
  const { nodes } = layers || {}
  const current = useMemo(
    () => nodes?.find((node) => node.id == activeLayer),
    [nodes, activeLayer]
  )

  const handleLayerChange = () => {
    if (!current || !current.parent) return
    document.dispatchEvent(layerChangeEvent(current.parent))

    setActiveLayer(Number(current.parent))
  }

  if (!current || !current.parent) return null

  return (
    <Item onClick={handleLayerChange}>
      <Icon />
      <p>{name}</p>
    </Item>
  )
}

const Item = styled.div`
  //width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  //padding: 2rem 1rem;
  position: relative;
  transition: background 0.3s, color 0.3s;

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    margin-top: 0.8rem;
  }

  .hover-icon,
  .hover-color,
  .hover-alt,
  .hover-fill {
    transition: all 0.3s;
  }

  img {
    z-index: 2;
    user-select: none;
  }

  background: ${(props) => props.theme.palette.background.primary};
  color: ${(props) => props.theme.palette.text.primary};

  .hover-icon {
    fill: transparent;
  }

  .hover-fill {
    fill: ${(props) => props.theme.palette.text.primary};
  }

  .hover-color {
    fill: ${(props) => props.theme.palette.text.primary};
    stroke: ${(props) => props.theme.palette.text.primary};
  }

  .hover-alt {
    fill: ${(props) => props.theme.palette.text.primary};
    stroke: ${(props) => props.theme.palette.background.primary};
  }
`

const Home = () => {
  const setSeat = useGlobalStore(state => state.setSeat)
  const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
  const navigate = useNavigate()
  const { layers } = useTree()

  const location = useLocation()
  const isTst = location.pathname.includes('/tst')
  const isHeadquarters = location.pathname.includes('/headquarters')

  const name = useSettingsSelector(settings => settings.name)
  const tstObj = useSettingsSelector(settings => settings.tst)
  const headquartersObj = useSettingsSelector(settings => settings.headquarters)

  const main = name || ''
  const tst = tstObj?.name || ''
  const headquarters = headquartersObj?.name || ''


  const onHomeClick = (event, variant: 'home' | 'tst' | 'headquarters') => {
    event.preventDefault()
    if (variant === 'tst') {
      navigate('/tst')
    } else if (variant === 'headquarters') {
      navigate('/headquarters')
    } else {
      navigate('/')
    }
    setSeat(null)
    if (layers && layers.layer) {
      setActiveLayer(Number(layers.layer.id))
    }
  }

  return (
    <HomeWrapper>
      <HomeButton href="/" onClick={(e) => onHomeClick(e, 'home')} active={!isTst && !isHeadquarters}>
        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="24px" height="24px">
          <path d="M 15 2 A 1 1 0 0 0 14.300781 2.2851562 L 3.3925781 11.207031 A 1 1 0 0 0 3.3554688 11.236328 L 3.3183594 11.267578 L 3.3183594 11.269531 A 1 1 0 0 0 3 12 A 1 1 0 0 0 4 13 L 5 13 L 5 24 C 5 25.105 5.895 26 7 26 L 23 26 C 24.105 26 25 25.105 25 24 L 25 13 L 26 13 A 1 1 0 0 0 27 12 A 1 1 0 0 0 26.681641 11.267578 L 26.666016 11.255859 A 1 1 0 0 0 26.597656 11.199219 L 25 9.8925781 L 25 6 C 25 5.448 24.552 5 24 5 L 23 5 C 22.448 5 22 5.448 22 6 L 22 7.4394531 L 15.677734 2.2675781 A 1 1 0 0 0 15 2 z M 18 15 L 22 15 L 22 23 L 18 23 L 18 15 z" />
        </svg>
        <Name>{main}</Name>
      </HomeButton>
      {/* <HomeButton href="/tst" onClick={(e) => onHomeClick(e, 'tst')} active={isTst}>
        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="24px" height="24px">
          <path d="M 15 2 A 1 1 0 0 0 14.300781 2.2851562 L 3.3925781 11.207031 A 1 1 0 0 0 3.3554688 11.236328 L 3.3183594 11.267578 L 3.3183594 11.269531 A 1 1 0 0 0 3 12 A 1 1 0 0 0 4 13 L 5 13 L 5 24 C 5 25.105 5.895 26 7 26 L 23 26 C 24.105 26 25 25.105 25 24 L 25 13 L 26 13 A 1 1 0 0 0 27 12 A 1 1 0 0 0 26.681641 11.267578 L 26.666016 11.255859 A 1 1 0 0 0 26.597656 11.199219 L 25 9.8925781 L 25 6 C 25 5.448 24.552 5 24 5 L 23 5 C 22.448 5 22 5.448 22 6 L 22 7.4394531 L 15.677734 2.2675781 A 1 1 0 0 0 15 2 z M 18 15 L 22 15 L 22 23 L 18 23 L 18 15 z" />
        </svg>
        <Name>{tst}</Name>
      </HomeButton> */}
      <HomeButton href="/headquarters" onClick={(e) => onHomeClick(e, 'headquarters')} active={isHeadquarters}>
        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="24px" height="24px">
          <path d="M 15 2 A 1 1 0 0 0 14.300781 2.2851562 L 3.3925781 11.207031 A 1 1 0 0 0 3.3554688 11.236328 L 3.3183594 11.267578 L 3.3183594 11.269531 A 1 1 0 0 0 3 12 A 1 1 0 0 0 4 13 L 5 13 L 5 24 C 5 25.105 5.895 26 7 26 L 23 26 C 24.105 26 25 25.105 25 24 L 25 13 L 26 13 A 1 1 0 0 0 27 12 A 1 1 0 0 0 26.681641 11.267578 L 26.666016 11.255859 A 1 1 0 0 0 26.597656 11.199219 L 25 9.8925781 L 25 6 C 25 5.448 24.552 5 24 5 L 23 5 C 22.448 5 22 5.448 22 6 L 22 7.4394531 L 15.677734 2.2675781 A 1 1 0 0 0 15 2 z M 18 15 L 22 15 L 22 23 L 18 23 L 18 15 z" />
        </svg>
        <Name>{headquarters}</Name>
      </HomeButton>
    </HomeWrapper>
  )
}

const HomeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  
    gap: 32px;
`

const HomeButton = styled.a<{ active?: boolean }>`
  
  text-decoration: none;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 8px;

    cursor: pointer;
  
    width: 150px;
  
  svg {
    flex-shrink: 0;
  }
  
  ${({ active }) => active ? css`
    color: #fff;
    background: #2C2C2C;
  ` : css`
    color: #2C2C2C;
    background: #fff;
  `}
`

const Name = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
`

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
    padding: 0 20px;
    height: 75px;
    box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
    /* border-radius: 0px 0px 8px 8px; */
    z-index: 2001;
    background: ${props => props.theme.palette.background.primary};
    color: ${props => props.theme.palette.text.primary};

    ${media.lg`
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        padding: 0 10px;
        border-radius: 0px;
    `}
`