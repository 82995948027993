import ModalService from "@/components/shared/modal/ModalService";
import BomjReportView from "@/components/layout/Sidebar/Reports/reports/bomj-report/BomjReport";
import { isSameDay } from "date-fns";
import { useReport } from "@/api/hooks/useReport";
import { useEffect } from "react";
import { RolesEnum, useUserStore } from "@/stores/userStore";

export const BOMJ_REPORT_ID =
  "NTNkMDIzNjcxMzYxNDdiOGI1MTg3ZDEwOTI1NmNlNzQ6XFBsdWdpbnNcYm9va2FibGVcbGliXHJlcG9ydHNcYm9talJlcG9ydA";

export const useCheckFreeEmployeesReport = () => {
  const role = useUserStore((state) => state.role);

  const { data, isLoading } = useReport({
    reportId: BOMJ_REPORT_ID,
    params: {
      withExtensions: true,
    },
    enabled: role === RolesEnum.Admin,
  });

  const showReport = () => {
    const shown = localStorage.getItem("no-place-users");
    if (!shown) {
      showReportAndMarkIt();
      return;
    }

    const isActive = isSameDay(new Date(shown), new Date());

    if (isActive) return;

    showReportAndMarkIt();
  };

  useEffect(() => {
    if (data && data.report) {
      const isNull = data.report.length <= 0;

      if (!isNull) {
        if (role === RolesEnum.Admin) {
          setTimeout(() => {
            showReport();
          }, 500);
        }
      }
    }
  }, [data]);
};

const showReportAndMarkIt = () => {
  ModalService.open(BomjReportView, {
    open: true,
    report: { name: "no-place-users", report_id: BOMJ_REPORT_ID },
  });
  localStorage.setItem("no-place-users", String(new Date()));
};
