import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { BookingService } from '../services/booking.service'

export const useCurrentBooking = ({ user }) => {
  const { workspaceId, projectId } = useProject()
  const { enqueueToast } = useToast()

  const { data, isLoading, refetch } = useQuery(
    ['current-booking', user],
    () => BookingService.getCurrentBooking({ workspaceId, projectId, user }),
    {
      enabled: !!workspaceId && !!projectId && !!user,
      select: ({ data }) => ({ items: data.items, total: data.total }),
      keepPreviousData: true,
      onError: () => {
        enqueueToast(
          {
            title: 'Ошибка!',
            message: 'Не удалось загрузить список бронирований'
          },
          { variant: 'error' }
        )
      }
    }
  )

  return { data, isLoading, refetch }
}
