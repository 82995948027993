import { useMyBookings } from '@/api/hooks/use-my-bookings'
import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useTree } from '@/api/hooks/useTree'
import Map from '@/components/shared/map/Map'
import { useToast } from '@/components/shared/toast/useToast'
import { useCheckFreeEmployeesReport } from '@/hooks/useCheckFreeEmployeesReport'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useProjectStore } from '@/stores/projectStore'
import { formatLocalDateToAPI } from '@/utils/helpers/dates.helpers'
import { addMinutes } from 'date-fns'
import qs from 'query-string'
import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AppLayout from '../components/layout/AppLayout'

const ProjectContainer: React.FC = () => {
  useCheckFreeEmployeesReport()
  const bookings = useMyBookings()

  return (
    <AppLayout>
      <Map />
      <SideEffector />
    </AppLayout>
  )
}

export default ProjectContainer

ProjectContainer.whyDidYouRender = true

const SideEffector = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { enqueueToast } = useToast()

  // data selectors
  const activeLayer = useGlobalStore((state) => state.activeLayer)
  const employee = useGlobalStore((state) => state.employee)
  const seat = useGlobalStore((state) => state.seat)
  const nodes = useProjectStore((state) => state.nodes)
  const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
  const setEmployee = useGlobalStore((state) => state.setEmployee)
  const seatEmployee = useGlobalStore((state) => state.seatEmployee)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const setSeat = useGlobalStore((state) => state.setSeat)
  const zoomSeat = useMapStore((state) => state.zoomSeat)
  const setZoomSeat = useMapStore((state) => state.setZoomSeat)
  const setNodes = useProjectStore((state) => state.setNodes)
  const setObjectTypes = useProjectStore((state) => state.setObjectTypes)

  const selection = useGlobalStore((state) => state.selection)
  const { data } = useBookingsForLayer(
    Number(activeLayer),
    formatLocalDateToAPI(selection.startDate),
    formatLocalDateToAPI(addMinutes(selection.endDate, -30))
  )
  const bookingData = useMemo(
    () => data?.bookings?.find((book) => Number(book.point_id) == seat),
    [data, seat]
  )

  const { metadata } = useMetadata()
  const objectTypes =
    useMemo(
      () =>
        metadata?.nodes.map((node) => ({
          value: node.uid,
          label: node.name
        })),
      [metadata?.nodes]
    ) || []

  const { layers } = useTree()
  const queryParams = qs.parse(location.search)
  const mapID = queryParams.map
  const employeeID = queryParams.employee
  const pointID = queryParams.point

  useEffect(() => {
    setObjectTypes(objectTypes)
  }, [objectTypes])

  useEffect(() => {
    if (layers) {
      if (layers.layer && !mapID) {
        setActiveLayer(Number(layers.layer.id))
      }
      setNodes(layers?.nodes)
    }
  }, [layers])

  useEffect(() => {
    if (mapID) {
      setActiveLayer(Number(mapID))
    }
    if (pointID) {
      // super experimental
      setTimeout(() => {
        setZoomSeat(Number(pointID))
      }, 1000)
    }
  }, [])

  useEffect(() => {
    if (employeeID) {
      setEmployee(Number(employeeID))
    }
  }, [employeeID])

  useEffect(() => {
    if (pointID) {
      setSeat(Number(pointID))
    }
  }, [pointID])

  useEffect(() => {
    if (bookingData && seatEmployee !== Number(bookingData.user_id)) {
      setSeatEmployee(Number(bookingData.user_id))
    }
  }, [bookingData])

  useEffect(() => {
    const newQueries = { ...queryParams, employee, point: null }

    navigate({
      pathname: location.pathname,
      search: qs.stringify(newQueries, { sort: false, skipNull: true })
    })
  }, [employee])

  useEffect(() => {
    const newQueries = {
      ...queryParams,
      map: activeLayer,
      point: seat,
      employee: null
    }
    const layer = nodes.find((node) => node.id === Number(activeLayer))

    if (!nodes.length) return

    if (!layer) {
      const parentLayer = nodes.find((node) => !node.parent) || nodes[0]
      enqueueToast(
        {
          title: 'Уровень не найден',
          message: 'Запрашиваемый уровень не найден.'
        },
        { variant: 'error' }
      )
      setActiveLayer(parentLayer.id)
      return
    }

    navigate({
      pathname: location.pathname,
      search: qs.stringify(newQueries, { sort: false, skipNull: true })
    })
  }, [seat, activeLayer, bookingData, nodes])

  return null
}

// SideEffector.whyDidYouRender = true
