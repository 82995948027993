import IFrameAuth from "@/components/IFrameAuth";
import NotFound from '@/components/NotFound';
import Loader from "@/components/Preloader/Loader";
import ProtectedRoutes from "@/components/ProtectedRoutes";
import useSettings from "@/hooks/use-settings";
import LogoutPage from "@/pages/logout";
import '@/settings.json';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LoginContainer from './LoginContainer';
import ProjectContainer from './ProjectContainer';

const AppContainer = () => {
    const { isLoading } = useSettings()

	if (isLoading) return <Loader />


    return (
        <Router>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<IFrameAuth />}>
                    <Route path="/login" element={<LoginContainer />} />
                    <Route path="/tst" element={<ProtectedRoutes />}>
                        <Route index element={<ProjectContainer />} />
                    </Route>
                    <Route path="/headquarters" element={<ProtectedRoutes />}>
                        <Route index element={<ProjectContainer />} />
                    </Route>
                    <Route element={<ProtectedRoutes />}>
                        <Route index element={<ProjectContainer />} />
                    </Route>
                    <Route path="/logout" element={<LogoutPage />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default AppContainer