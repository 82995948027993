import { useTree } from "@/api/hooks/useTree"
import { IElevator } from "@/hooks/settings/settings.interface"
import { useSettingsSelector } from "@/hooks/settings/use-settings-selector"
import { useGlobalStore } from "@/stores/globalStore"
import { useMemo } from "react"
import { findNodeById, findParentAndFloorIds } from "./utils"

export const useElevator = () => {
    const { layers, isLoading } = useTree()
    const tree = layers?.tree || []

    const elevator = useSettingsSelector(settings => settings.elevator, [] as IElevator[])
    const activeLayer = useGlobalStore((state) => state.activeLayer)
    const elevatorBuildings = useMemo(() => {
        let buildings = {}
        elevator.forEach(el => {
            buildings[el.parent] = findNodeById(tree, el.parent)?.ids || []
        })
        return buildings
    }, [elevator, tree])
    const currentBuilding = useMemo(() => {
        let building: number | null = null
        Object.entries(elevatorBuildings).forEach(([key, value]) => {
            const buildingId = Number(key)
            const ids = [buildingId, ...(value as number[])]
            if (!ids.includes(Number(activeLayer))) return
            building = buildingId 
        })

        return { building, floors: elevator.find(el => el.parent == building)?.floors || [] }
    }, [elevatorBuildings, activeLayer])

    const { parent, floors } = findParentAndFloorIds(tree, currentBuilding.building, currentBuilding.floors)

    return {
        isActive: !!currentBuilding.building,
        building: currentBuilding,
        floors,
        parent,
        isLoading
    }
}
