import { fetchJSON } from "@/api/utils";
import { useToast } from "@/components/shared/toast/useToast";
import { ISettings } from "@/hooks/settings/settings.interface";
import { useQuery } from "react-query";

export const formatURL = (url: string) => {
    return url.replace(/([^:]\/)\/+/g, '$1')
}

export function isNonNullable<T>(value: T): value is NonNullable<T> {
    return typeof value !== 'undefined' && value !== null
}

const fetchSettings = async (version: number) =>
    await fetchJSON<ISettings>(
        // TODO: mb return cache ?v=
        `/settings.json?v=${version}`,
        'No settings file found',
    )

export const useSettings = () => {
    const {enqueueToast} = useToast()
    return useQuery({
        queryKey: ['settings'],
        queryFn: () => fetchSettings(Date.now()),
        staleTime: 5 * 60 * 1000,
        retry: false,
        // TODO: refactor for v5 @tanstack/react-query where onSuccess and onError will be remover from useQuery
        select: (data) => ({
            ...data,
        }),
        onError: () => {
            enqueueToast({ title: 'Ошибка', message: 'Не удалось загрузить настройки приложения' }, { variant: 'error' })
        },
    })
}

export default useSettings