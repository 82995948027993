import DraggableIcon from "../../icon/DraggableIcon";
import React from "react";
import styled from "styled-components";

const DraggableControl = ({ className }) => {
    return  <Wrapper className={className}>
        <DraggableIcon size={32} />
    </Wrapper>
}

export default DraggableControl

const Wrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  
  cursor: grab;
  
  &:active {
    cursor: grabbing;
  }
`