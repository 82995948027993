import { translate } from '@/i18n'
import Grid from '@/ui/components/Grid'
import { useFormikContext } from 'formik'
import { ErrorBoundary } from 'react-error-boundary'
import BookingGridSelector from './BookingGridSelector'

const BookingGridField = ({ bookings, addItem, disabled = false }) => {
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' })
  const { values } = useFormikContext<any>()

  // if (values.type === '3' || !isBigScreen) return null
  // if (values.type === '3') return null

  return (
    <ErrorBoundary
      fallback={<div>{translate('calendar-initialize-error')}</div>}
    >
      <Grid container style={{ pointerEvents: disabled ? 'none' : 'all' }}>
        <Grid item xs={12} md={12}>
          <BookingGridSelector
            bookings={bookings}
            addItem={addItem}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

export default BookingGridField
