import { ProjectService } from '@/api/services/project.service'
import { useQuery } from 'react-query'
import { useProject } from '@/hooks/useProject'
import { BookingTypeEnum } from '@/api/bookings'

export const useBookingsSearch = (term: string = '') => {
  const { workspaceId, projectId } = useProject()

  return useQuery(
    ['bookings-search', term],
    () =>
      ProjectService.search({
        workspaceId,
        projectId,
        term,
        point: false,
        user: true,
        layer: false
      }),
    {
      enabled: Boolean(workspaceId) && Boolean(projectId),
      select: ({ data }) => {
        const items = data.search_result?.[0] || []
        return items.filter(getLocations).map(castToBooking)
      }
    }
  )
}

const getBooking = (item) => {
  try {
    const location = Object.keys(item).find((key) => key.includes('locations'))

    const bookings = location ? item[location] : ''
    return JSON.parse(bookings)
  } catch (e) {
    return undefined
  }
}

const getUserFields = (item) => {
  try {
    const fields = Object.keys(item).find((key) => key.includes('fields'))

    const userFields = fields ? item[fields] : ''
    return JSON.parse(userFields)
  } catch (e) {
    return undefined
  }
}

const getLocations = (item) => {
  const validBookings = getBooking(item)

  return validBookings ? validBookings.length > 0 : false
}

const castToBooking = (temp) => {
  const booking = getBooking(temp)?.at(0)
  const fields = getUserFields(temp)

  if (!booking) return null

  const uBooking = {
    ...temp,
    id: booking.booking_id,
    start: booking.begin,
    end: booking.end,
    rec: 0,
    booking_type: booking.end
      ? BookingTypeEnum.Regular
      : BookingTypeEnum.Constant,
    name: booking.place_name,
    parent_layer_id: booking.layer_id,
    is_master: '0',
    user: temp.display,
    gap: [],
    location: [],
    bookable_id: booking.place_id
  }

  fields.forEach((field) => {
    uBooking[field.label] = field.value
  })

  return uBooking
}
//
// {
//     "id": "1368",
//     "login": "emil",
//     "type": "user",
//     "display": "Баранов Эмиль Маркович",
//     "role": "client",
//     "f53d02367136147b8b5187d109256ce74category": "",
//     "f53d02367136147b8b5187d109256ce74locations": "[{\"booking_id\":\"2110\",\"begin\":\"2022-10-31T21:00:00\",\"end\":null,\"place_id\":\"10572\",\"place_name\":\"309.13\",\"place_type\":\"6536fae1ae124271bede9259e0ccf036\",\"layer_id\":\"1175\"}]",
//     "fd56e0e5438ea4f7e81c820fdd380ef4efields": "[{\"value\":\"emil@officescheme.ru\",\"label\":\"Почта\",\"type\":\"string\"},{\"value\":\"+7 (900) 000 00 02\",\"label\":\"Телефон\",\"type\":\"phone\"},{\"value\":\"BackOffice\",\"label\":\"Департамент\",\"type\":\"string\"},{\"value\":\"Бухгалтер\",\"label\":\"Должность\",\"type\":\"string\"}]",
//     "rank": "10959.1875"
// }

// id: string;
// start: string;
// end: string;
// rec: string;
// booking_type: string;
// name: string;
// parent_layer_id: string;
// is_master: string;
// user: string;
// gap: string;
// location: string[];
// bookable_id: string;
