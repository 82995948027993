import { BookingItem } from '@/api/bookings'
import { useBookings } from '@/api/hooks/useBookings'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import { RuntimeFieldData } from '@/api/services/user.service'
import Close from '@/components/Close'
import Modal from '@/components/Modal/Modal'
import Toolbar from '@/components/Toolbar/Toolbar'
import useResponsive from '@/hooks/useResponsive'
import useSorting from '@/hooks/useSorting'
import { translate } from '@/i18n'
import { useProjectStore } from '@/stores/projectStore'
import { Input } from '@/ui/components/Field/Input'
import { SelectInput } from '@/ui/components/Field/Select'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import { printPDF } from '@/utils/func/print'
import { formatToReport } from '@/utils/helpers/dates.helpers'
import { addMinutes, format, parseISO } from 'date-fns'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import {
  BookingDate,
  bookingTypeTranslate,
  extractSchedule
} from '../../Bookings'
import MobileBookingItem from '../../MobileBookingItem'
import { ReportButton } from '../ReportView'
import SingleDatePicker from '../pickers/SingleDatePicker'

type ReportModalProps = {
  report: string | null
  item: {
    name: string
    key: string
  }
  onClose: () => void
}

const weekdays = {
  1: 'Пн.',
  2: 'Вт.',
  3: 'Ср.',
  4: 'Чт.',
  5: 'Пт.',
  6: 'Сб.',
  0: 'Вс.'
}

export const getRecurrentDates = (item) => {
  const { gap } = item

  const gapToSlots = Array.isArray(gap) ? gap.join(',') : gap
  const schedule = extractSchedule(gapToSlots.split(','))
  const d = new Date()
  const offset = d.getTimezoneOffset()

  return `${`${format(
    parseISO(item.start + '.000Z'),
    'dd.MM.yyyy HH:mm'
  )} - ${format(
    parseISO(item.end + '.000Z'),
    'dd.MM.yyyy HH:mm'
  )}`}\r${schedule.map(
    (slot) =>
      `${weekdays[new Date(slot.start).getDay()]} ${format(
        addMinutes(slot.start, -offset),
        'HH:mm'
      )} - ${format(addMinutes(slot.end, -offset), 'HH:mm')}`
  )}`
}

const BookingsReportModal: React.FC<ReportModalProps> = ({
  report,
  item,
  onClose
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const nodes = useProjectStore((state) => state.nodes)
  const [day, setDay] = useState<Date | null>(new Date())
  const [parent, setParent] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [toggle, setOpen] = useState<boolean>(false)
  const [selection, setSelection] = useState<{
    startDate: any
    endDate: any
    key: string
  }>({ startDate: new Date(), endDate: new Date(), key: 'selection' })
  const [name, setName] = useState<string>('')
  const intl = useIntl()
  const { data: userFields } = useUserCardFields()

  const fields = useMemo(
    () => userFields?.fields.filter((v) => v.alias !== 'booking_category'),
    [userFields]
  )
  const { sort, direction, handleSort } = useSorting()
  const { metadata } = useMetadata()
  const { isDesktop } = useResponsive()

  const handleNameChange = (e) => setName(e?.target?.value)
  const handleParentChange = (e) => setParent(e?.target?.value)
  const handleTypeChange = (e) => setType(e?.target?.value)

  const { data } = useBookings({
    page: 1,
    perPage: 20000,
    moment: 'current',
    day: selection.startDate || new Date(),
    name,
    placeType: type,
    sort,
    direction
  })

  const debouncedNameResponse = useMemo(() => {
    return debounce(handleNameChange, 500)
  }, [])

  useEffect(() => {
    return () => debouncedNameResponse.cancel()
  }, [])

  const colHeaders = [
    { label: 'ФИО', key: 'user', alias: 'report-full-name' },
    { label: 'Место', key: 'name', alias: 'report-object' }
    // { label: 'Тип', key: 'bookingType', alias: 'report-booking-type' },
    // { label: 'Дата', key: 'date', alias: 'report-date' }
  ].map((item) => ({ ...item, label: intl.formatMessage({ id: item.alias }) }))

  const headers = [
    ...colHeaders,
    ...(fields || []).map((field) => ({
      label: field.label,
      key: field.uid,
      alias: field.alias
    }))
  ]

  const reportItems = data?.items || []

  const pdfCols = useMemo(
    () => headers.map((col) => ({ header: col.label, dataKey: col.key })),
    [headers]
  )
  const formatData = useMemo(() => {
    return data
      ? reportItems.map((item) => {
          const bookingType =
            item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant'
          const date =
            bookingType !== 'recurrent'
              ? `${format(
                  parseISO(item.start + '.000Z'),
                  'dd.MM.yyyy HH:mm'
                )} ${
                  bookingType !== 'constant'
                    ? `- ${format(
                        parseISO(item.end + '.000Z'),
                        'dd.MM.yyyy HH:mm'
                      )}`
                    : ''
                }`
              : getRecurrentDates(item)
          // const date = `${format(parseISO(item.start + '.000Z'), 'dd.MM.yyyy HH:mm')} ${bookingType !== 'constant' ?  `- ${format(parseISO(item.end + '.000Z'), 'dd.MM.yyyy HH:mm')}` : ''}`

          return {
            ...item,
            date,
            bookingType: bookingTypeTranslate[bookingType]
          }
        })
      : []
  }, [reportItems])

  console.log(formatData)

  return (
    <Modal isOpen={!!report} maxWidth={1366} onClose={onClose}>
      <Header>
        <Title>
          {translate('report-template', {
            name: intl.formatMessage({ id: item.key })
          })}
        </Title>
        <ExportWrapper>
          <ReportButton
            onClick={() =>
              printPDF({
                name: report,
                columns: pdfCols,
                body: formatData
              })
            }
          >
            PDF
          </ReportButton>
          <CSVLink
            data={formatData}
            headers={headers}
            separator={';'}
            filename={report + ` ${formatToReport(new Date())}`}
          >
            <ReportButton>CSV</ReportButton>
          </CSVLink>
          <Close color="#000" onClick={onClose} />
        </ExportWrapper>
      </Header>

      <Toolbar>
        <Toolbar.Item xs={6} md={4}>
          <Toolbar.BlackLabel>{translate('date')}</Toolbar.BlackLabel>
          <Input
            $fullWidth
            placeholder={intl.formatMessage({ id: 'start-datetime' })}
            value={`${format(selection.startDate, 'dd.MM.yyyy')}`}
            onClick={() => setOpen(true)}
          />
          <SingleDatePicker
            open={toggle}
            setOpen={setOpen}
            selection={selection}
            setSelection={setSelection}
          />
        </Toolbar.Item>
        <Toolbar.Item xs={6} md={4}>
          <Toolbar.BlackLabel>{translate('position')}</Toolbar.BlackLabel>
          <SelectInput
            $fullWidth
            defaultValue={parent}
            onChange={handleParentChange}
          >
            <option value="">{translate('all-levels')}</option>
            {nodes.map((node) => (
              <option key={node.id} value={node.id}>
                {node.name}
              </option>
            ))}
          </SelectInput>
        </Toolbar.Item>
        <Toolbar.Item xs={6} md={4}>
          <Toolbar.BlackLabel>{translate('object-types')}</Toolbar.BlackLabel>
          <SelectInput
            $fullWidth
            defaultValue={type}
            onChange={handleTypeChange}
          >
            <option value="">{translate('all')}</option>
            {metadata?.nodes?.map((node) => (
              <option key={node.uid} value={node.uid}>
                {node.name}
              </option>
            ))}
          </SelectInput>
        </Toolbar.Item>
      </Toolbar>

      <Table>
        {isDesktop && (
          <TableHead>
            <tr>
              {headers.map((header) => (
                <TableHeadCell key={header.key}>{header.label}</TableHeadCell>
              ))}
            </tr>
          </TableHead>
        )}

        <TableBody>
          {data && data.items.length ? (
            data?.items
              .slice((currentPage - 1) * 20, currentPage * 20)
              .map((item) => (
                <ElementItem key={item.id} item={item} fields={fields || []} />
              ))
          ) : (
            <tr>
              <td colSpan={4}>
                <NotFound>{translate('no-results')}</NotFound>
              </td>
            </tr>
          )}
        </TableBody>
      </Table>

      <Pagination
        inverse
        currentPage={currentPage}
        total={data?.total || 0}
        handlePageChange={setCurrentPage}
      />
    </Modal>
  )
}

export default BookingsReportModal

const TableHeadCell = styled.th`
  text-align: left;
`

const TableBody = styled.tbody``

const TableHead = styled.thead`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`

const Table = styled.table`
  margin: 1rem 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  tr td:last-child {
    width: 1%;
    white-space: nowrap;
  }

  th,
  td {
    padding: 6px;
    line-height: 1;
  }
`

const TableCell = styled.td`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
`

const ElementItem: React.FC<{
  item: BookingItem
  fields: RuntimeFieldData[]
}> = ({ item, fields }) => {
  const bookingType =
    item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant'
  const { isDesktop } = useResponsive()

  if (!isDesktop)
    return (
      <MobileBookingItem
        fio={item.user}
        item={item}
        name={item.name}
        type={bookingTypeTranslate[bookingType]}
        date={
          <BookingDate
            type={bookingType}
            gap={item.gap}
            date={{ start: item.start, end: item.end }}
          />
        }
        fields={fields}
      />
    )

  return (
    <tr>
      <TableCell>{item.user}</TableCell>
      <TableCell>{item.name}</TableCell>
      {/* <TableCell>{bookingTypeTranslate[bookingType]}</TableCell>
      <TableCell>
        <ErrorBoundary fallback={<div>Ошибка отображения даты</div>}>
          <BookingDate
            type={bookingType}
            gap={item.gap}
            inverse={true}
            date={{ start: item.start, end: item.end }}
          />
        </ErrorBoundary>
      </TableCell> */}
      {fields.map((prop) => (
        <TableCell key={prop.uid}>
          {item[prop.uid] || item[prop.label]}
        </TableCell>
      ))}
    </tr>
  )
}

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg`
      flex-direction: column;
      align-items: flex-start;
  `}
`

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }

  ${media.lg`
      margin-top: 20px;
  `}
`

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`
