import { useGlobalStore } from "@/stores/globalStore"
import { useMemo } from "react"
import styled, { css } from "styled-components"

export const Floor = ({ item }) => {
    const { name, layer, childs = [] } = item
    const activeLayer = useGlobalStore((state) => state.activeLayer)
    const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
    const isCurrentFloor = useMemo(() => [layer, ...childs].includes(activeLayer), [activeLayer, childs])

    const handleNavigate = () => {
        setActiveLayer(layer)
    }

    return (
        <FloorButton onClick={handleNavigate} $active={isCurrentFloor}>{name}</FloorButton>
    )
}

const FloorButton = styled.div<{ $active: boolean }>`
   height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.3s;
    flex-shrink: 0;

    background: #079DAC;
    border: 1px solid #079DAC;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;


    ${props => props.$active ? css`
        background: #fff;
        border: 1px solid #fff;
        color: #079DAC;
    ` : css`
        background: #079DAC;
        border: 1px solid #079DAC;
        color: #fff;
    `}

    &:first-child {
        margin-bottom: 8px;
    }

    &:hover {
        opacity: 0.7;
    }

    &:active {
        transform: scale(0.8);
    }
`