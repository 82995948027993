import { StatusResponseEnum } from '@/api'
import { BookingItem } from '@/api/bookings'
import { BookingService } from '@/api/services/booking.service'
import { confirmDialog } from '@/components/ConfirmDialog'
import { DeleteIcon, EditIcon } from '@/components/icons'
import CloseIcon from '@/components/icons/CloseIcon'
import PointIcon from '@/components/icons/PointIcon'
import { bookingDialog } from '@/components/shared/booking/form/BookingModal'
import { useToast } from '@/components/shared/toast/useToast'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { useProject } from '@/hooks/useProject'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { format, parseISO } from 'date-fns'
import { FC, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'

export const MyBookingsViewer = ({ items, close }) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, close)

  return (
    <Wrapper ref={ref}>
      <Container>
        <Header>
          <Title>Мои брони</Title>
          <div style={{ cursor: 'pointer', padding: 8 }} onClick={close}>
            <CloseIcon color="#000" />
          </div>
        </Header>
        {/* <TextDescription>
          Показано 5 последних активных бронирований
        </TextDescription> */}

        <Form>
          {items.map((item) => (
            <BookingElementItem key={item.id} item={item} />
          ))}
        </Form>

        {/* <div>
          <PrimaryButton $fullWidth onClick={close}>
            Закрыть
          </PrimaryButton>
        </div> */}
      </Container>
    </Wrapper>
  )
}

const BookingElementItem: FC<{ item: BookingItem }> = ({ item }) => {
  const queryClient = useQueryClient()
  const { workspaceId, projectId } = useProject()
  const { enqueueToast } = useToast()

  const setSection = useGlobalStore((state) => state.setSection)
  const setSeat = useGlobalStore((state) => state.setSeat)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
  const setZoomSeat = useMapStore((state) => state.setZoomSeat)
  const setZoomSeatPrev = useMapStore((state) => state.setZoomSeatPrev)
  const intl = useIntl()

  const handleSeatView = (e) => {
    e.preventDefault()

    setActiveLayer(Number(item.parent_layer_id))
    setSeatEmployee(null)
    setSeat(Number(item.bookable_id))
    setZoomSeat(Number(item.bookable_id))
    setZoomSeatPrev(Number(item.bookable_id))

    setSection('user')
  }
  const removeBooking = async () => {
    try {
      const response = await BookingService.removeBooking({
        workspaceId,
        projectId,
        bookingId: Number(item.id)
      })

      if (response && response.data.status === StatusResponseEnum.Success) {
        queryClient.refetchQueries(['bookings'])

        // remove cache data for map
        queryClient.refetchQueries('bookings_for_layer')

        enqueueToast(
          {
            title: intl.formatMessage({ id: 'success' }),
            message: intl.formatMessage({ id: 'booking-deleted' })
          },
          { variant: 'success' }
        )
      }
    } catch (e) {
      enqueueToast(
        {
          title: intl.formatMessage({ id: 'error' }),
          message: intl.formatMessage({ id: 'failed-to-delete-booking' })
        },
        { variant: 'error' }
      )
    }
  }

  const handleEdit = () =>
    bookingDialog({ bookingId: Number(item.id), isBookingFree: true })
  const handleDelete = (e) => {
    return confirmDialog({
      title: intl.formatMessage({ id: 'delete-booking' }),
      message: intl.formatMessage({ id: 'delete-booking-confirm' }),
      onSubmit: removeBooking
    })
  }

  return (
    <ItemWrapper>
      <Name>{[...item.location, item.name].join('. ').trim()}</Name>
      <Date>
        {`${format(
          parseISO(item.start + '.000Z'),
          'dd.MM.yyyy HH:mm'
        )} - ${format(parseISO(item.end + '.000Z'), 'dd.MM.yyyy HH:mm')}`}
      </Date>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 8,
            marginTop: 12
          }}
        >
          <ActionButton onClick={handleSeatView}>
            <PointIcon color="#fff" />
          </ActionButton>
          <ActionButton onClick={handleEdit}>
            <EditIcon />
          </ActionButton>
          <ActionButton onClick={handleDelete}>
            <DeleteIcon />
          </ActionButton>
        </div>
      </div>
    </ItemWrapper>
  )
}

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const Date = styled.div`
  font-size: 14px;
  font-weight: 400;
`

const ItemWrapper = styled.div`
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); */
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  background-color: #efefef;
`

const ActionButton = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #000;
  border-radius: 50%;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Wrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  margin-right: 26px;
  box-shadow: 4px 8px 8px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 20px;
  min-width: 220px;
  max-width: 500px;
  width: 100%;
`

const InputLabel = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 600;
`

const TextDescription = styled.div`
  font-size: 12px;
  color: #a3a3a3;
  margin-bottom: 16px;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #2c2c2c;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
