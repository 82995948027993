import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import React from 'react'
import styled from 'styled-components'
import translate from './../../../i18n/translate'

const MobileBookingItem: React.FC<any> = ({
  fio,
  item,
  name,
  isNow,
  parentName = '',
  handleEdit = null,
  handleDelete = null,
  handleSeatView = null,
  fields = []
}) => {
  return (
    <ItemWrapper
      style={{ background: isNow ? 'rgba(255, 255, 0, 0.75)' : '#fff' }}
    >
      {fio && <Name>{fio}</Name>}
      <Name>{name}</Name>
      <PropsWrapper>
        {/*<Property>*/}
        {/*  <PropName>{translate("parent-location")}</PropName>*/}
        {/*  <PropValue>{parentName}</PropValue>*/}
        {/*</Property>*/}
        {fields.map((prop) => (
          <Property key={prop.uid}>
            <PropName>{prop.label}</PropName>
            <PropValue>{item[prop.uid] || item[prop.label]}</PropValue>
          </Property>
        ))}
      </PropsWrapper>
      {handleEdit || handleDelete || handleSeatView ? (
        <FormControls>
          {handleSeatView && (
            <PrimaryButton onClick={handleSeatView}>
              {translate('show-on-map')}
            </PrimaryButton>
          )}
          {handleEdit && (
            <PrimaryButton onClick={handleEdit}>
              {translate('edit')}
            </PrimaryButton>
          )}
          {handleDelete && (
            <DefaultButton onClick={handleDelete}>
              {translate('delete')}
            </DefaultButton>
          )}
        </FormControls>
      ) : (
        ''
      )}
    </ItemWrapper>
  )
}

export default MobileBookingItem

const FormControls = styled.div`
  display: flex;
  align-items: center;
  //justify-content: flex-end;
  row-gap: 16px;
  flex-wrap: wrap;
  margin-top: 24px;
  max-width: 600px;
  width: 100%;

  & > {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`

const ItemWrapper = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 15px 10px;
  color: #000;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

const PropsWrapper = styled.div`
  margin: 6px 0 12px 0;
`

const PropName = styled.div`
  /* max-width: 50px; */
  /* width: 100%; */
`
const PropValue = styled.div`
  /* width: 100%; */
  font-weight: 700;
  margin-left: 16px;
`

const Property = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #000000;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`
